import { useTranslation } from 'react-i18next';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useParams } from 'react-router';
import { API, graphqlOperation } from 'aws-amplify';
import { onEmployeeDashboardUpdate } from '@gym-graphql/subscriptions';
import type { Observable } from 'redux';
import type { KisiEvent } from '@gym-src/API';
import type { GymType } from '@gym-particles/types/Gym';
import { fetchGyms } from '@gym-redux/slices/gymsSlice';
import { UserRole } from '@gym-particles/types/User';
import Avatar from '@gym-atoms/Avatar/Avatar';
import moment from 'moment';
import { KisiEvents, LastKisiEvent } from '@gym-particles/types/KisiEvents';
import Loader from '@gym-atoms/Loader/Loader';
import { fetchKisiEvents } from '@gym-redux/slices/kisiEventSlice';
import dayjs from 'dayjs';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import { fetchKisiEventMembers } from '@gym-redux/slices/kisiEventMembersSlice';
import { KisiEventMembers } from '@gym-particles/types/KisiEventMembers';
import { useRef } from 'react';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { linkMemberToEvent } from '@gym-graphql/mutations';
const getTimeString = () => moment().format('dddd D MMMM h:mm a');
import memberPlaceholder from '@gym-assets/images/staff-image-placeholder.jpg';
import i18n from '@gym-particles/i18next';
import { MemberBookingDetails, MemberDetails } from '@gym-particles/types/Member';
import { getMemberBookingDetails, getMemberDetails } from '@gym-graphql/queries';
import { SiteLocationMemberDetails } from '@gym-src/API';

const MainColumn = () => {
  const fullScreenHandle = useFullScreenHandle();
  const { siteLocationId, gymChainId, doorId } = useParams<{
    siteLocationId: string;
    gymChainId: string;
    doorId: string;
  }>();
  const [gym, setGym] = useState<GymType>(null);
  const gyms = useAppSelector((state) => state.gyms.items);
  const dispatch = useAppDispatch();
  const { userRole, userId } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const [timeString, setTimeString] = useState(getTimeString());
  const isEventsLoading = useAppSelector((state) => state.kisiEvent.isKisiEventLoading);
  const isLinkingMemberLoading = useAppSelector(
    (state) => state.kisiEventMember.isKisiEventMembersLoading
  );
  const [kisiEventData, setKisiEventData] = useState<KisiEvents[]>([]);
  const [kisiEvents, setKisiEvents] = useState<KisiEvents[]>([]);
  const [lastKisiDoorUnlockEvent, setLastKisiDoorUnlockEvent] = useState<LastKisiEvent>();
  const [kisiEventMembersData, setKisiEventMembersData] = useState<KisiEventMembers[]>([]);
  const [memberDetails, setMemberDetails] = useState<Array<MemberDetails>>([]);
  const [memberBookingDetails, setMemberBookingDetails] = useState<Array<MemberBookingDetails>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLinkingDialogVisible, setIsLinkingDialogVisible] = useState(false);
  const [memberLinked, setMemberLinked] = useState(false);
  const [unlinkedDoorOpenEvent, setUnlinkedDoorOpenEvent] = useState<KisiEvents[]>([]);
  const [eventEmail, setEventEmail] = useState<string>('');
  const [linkMemberVariables, setLinkMemberVariables] = useState<{
    memberId: number;
    eventId: number;
    buttonEvent: EventTarget;
  }>();

  const fetchMemberDetails = async () => {
    setIsFetching(true);
    const response = await (API.graphql({
      query: getMemberDetails,
      variables: {
        memberId: lastKisiDoorUnlockEvent.memberId
      }
    }) as Promise<{
      data: { getMemberDetails: [SiteLocationMemberDetails] };
    }>);

    const details = response.data.getMemberDetails.map((d) => {
      return {
        membership: d.membership,
        memberLocationMembershipId: d.memberLocationMembershipId,
        subscriptionDeleted: d.subscriptionDeleted,
        validFrom: d.validFrom ? dayjs(d.validFrom || '').format('DD MMM YYYY') : '',
        validTo: d.validTo ? dayjs(d.validTo || '').format('DD MMM YYYY') : '',
        subscriptionCreatedDate: d.subscriptionCreatedDate,
        subscriptionModifiedDate: d.subscriptionModifiedDate,
        subscriptionIsSelected: d.subscriptionIsSelected && !d.subscriptionDeleted ? 'Yes' : 'No',
        subscriptionStatus: d.subscriptionStatus
          ? d.subscriptionStatus.charAt(0).toUpperCase() + d.subscriptionStatus.slice(1)
          : '',
        scheduleType: d.scheduleType || '',
        remainingSessions: d.remainingSessions || 0,
        siteLocationMembershipStatus: d.siteLocationMembershipStatus
          ? d.siteLocationMembershipStatus.charAt(0).toUpperCase() +
            d.siteLocationMembershipStatus.slice(1)
          : ''
      };
    }) as MemberDetails[];
    setMemberDetails(details);
    setIsFetching(false);
  };
  useEffect(() => {
    console.log({ lastKisiDoorUnlockEvent });
    if (lastKisiDoorUnlockEvent) fetchMemberDetails();
  }, [lastKisiDoorUnlockEvent]);

  const fetchBookingDetails = async () => {
    setIsFetching(true);
    const response = await (API.graphql({
      query: getMemberBookingDetails,
      variables: {
        memberId: lastKisiDoorUnlockEvent.memberId
      }
    }) as Promise<{
      data: { getMemberBookingDetails: [MemberBookingDetails] };
    }>);
    let details = [];
    if (response.data.getMemberBookingDetails !== null) {
      details = response.data.getMemberBookingDetails
        .filter((x) => !x.isDeleted)
        .map((d) => {
          return {
            booking: d.booking,
            appointmentId: d.appointmentId,
            importedId: d.importedId,
            isVisited: d.isVisited ? 'True' : 'False',
            startDateTime: d.startDateTime
              ? dayjs(d.startDateTime || '').format('DD MMM YYYY HH:mm:ss')
              : '',
            endDateTime: d.endDateTime
              ? dayjs(d.endDateTime || '').format('DD MMM YYYY HH:mm:ss')
              : '',
            createdDate: d.createdDate,
            lastModifiedDate: d.lastModifiedDate,
            membershipName: d.membershipName,
            siteLocation: d.siteLocation
          };
        }) as MemberBookingDetails[];
    }
    setMemberBookingDetails(details);
    setIsFetching(false);
  };
  useEffect(() => {
    if (lastKisiDoorUnlockEvent) fetchBookingDetails();
  }, [lastKisiDoorUnlockEvent]);

  const LinkingDialogFooter = () => {
    return (
      <div>
        <>
          <Button
            data-cy={'zone-dialog-cancel-btn'}
            label={t('COMMON.CANCEL')}
            variant={ButtonVariants.textonly}
            onClick={() => {
              setEventEmail('');
              setIsLinkingDialogVisible(false);
              fullScreenHandle.enter();
            }}
          />
        </>
      </div>
    );
  };

  const kisiEventList =
    useAppSelector(
      (state) => state.kisiEvent.items.find((g) => g.siteLocationId === +siteLocationId)?.items
    ) || [];

  const kisiEventMemberList =
    useAppSelector(
      (state) => state.kisiEventMember.items.find((g) => g.memberEmail === eventEmail)?.items
    ) || [];

  const totalEventCount =
    useAppSelector(
      (state) =>
        state.kisiEvent.items.find((g) => g.siteLocationId === +siteLocationId)?.totalRecords
    ) || 0;

  useEffect(() => {
    dispatch(
      fetchKisiEvents({
        siteLocationId: +siteLocationId
      })
    );
    setMemberLinked(false);
  }, [siteLocationId, memberLinked]);

  useEffect(() => {
    if (!eventEmail) return;
    dispatch(
      fetchKisiEventMembers({
        memberEmail: eventEmail
      })
    );
  }, [eventEmail]);

  useEffect(() => {
    if (kisiEventMemberList.length > 0) {
      setKisiEventMembersData(kisiEventMemberList);
      fullScreenHandle.exit();
      setIsLinkingDialogVisible(true);
    }
  }, [kisiEventMemberList]);

  useEffect(() => {
    console.log({ kisiEventList });
    setLastKisiDoorUnlockEvent(
      kisiEventList.filter((kisiEvent) => kisiEvent.kisiDoorId === +doorId)[0]
    );
    setKisiEvents(kisiEventList);
  }, [kisiEventList]);

  const DOOR_EVENTS_TABLE_HEAD = [
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.KISI_DOOR_NAME_HEADER'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.KISI_DOOR_OPEN_TIME_HEADER'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.KISI_DOOR_OPEN_GYMAXESS_MEMBER_NAME_HEADER'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.KISI_DOOR_OPEN_GYMAXESS_MEMBER_CLIENT_ID_HEADER')
  ];

  const LINKABLE_MEMBERS_TABLE_HEAD = [
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.MEMBER_NAME'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.MEMBER_UNIQUE_ID'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.MEMBER_CLIENT_ID'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.MEMBER_EMAIL'),
    ''
  ];

  useEffect(() => {
    dispatch(
      fetchGyms({
        id: +gymChainId,
        pagination: {
          offset: 0,
          pageSize: 0,
          sortField: 'siteId',
          sortOrder: 1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        }
      })
    );
  }, [dispatch, gymChainId, userRole, userId]);

  useEffect(() => {
    const extractedGyms = gyms.map((g) => g.items.find((_g) => _g.gymId === +siteLocationId));
    const selectedGym = extractedGyms.find((g) => g && g.gymId === +siteLocationId);
    if (selectedGym) setGym(selectedGym);
  }, [gyms, siteLocationId]);

  useEffect(() => {
    if (!siteLocationId) return;
    const subscription = ((API.graphql(
      graphqlOperation(onEmployeeDashboardUpdate)
    ) as unknown) as Observable<{
      value: { data: { onEmployeeDashboardUpdate: KisiEvent[] } };
    }>).subscribe({
      next: (data) => {
        if (data.value.data.onEmployeeDashboardUpdate[0].siteLocationId === +siteLocationId) {
          setKisiEvents(data.value.data.onEmployeeDashboardUpdate);
          const newKisiDoorOpening = data.value.data.onEmployeeDashboardUpdate.filter(
            (kisiEvent) => kisiEvent.kisiDoorId === +doorId
          )[0];
          console.log({ newKisiDoorOpening });
          console.log({ lastKisiDoorUnlockEvent });
          if (
            newKisiDoorOpening &&
            lastKisiDoorUnlockEvent &&
            (newKisiDoorOpening.doorOpenMemberEmail !==
              lastKisiDoorUnlockEvent.doorOpenMemberEmail ||
              new Date(newKisiDoorOpening.doorOpenedTime).getTime() !==
                new Date(lastKisiDoorUnlockEvent.doorOpenedTime).getTime() ||
              newKisiDoorOpening.kisiDoorId !== lastKisiDoorUnlockEvent.kisiDoorId)
          ) {
            console.log('setting kisi event');
            setLastKisiDoorUnlockEvent(newKisiDoorOpening);
            const audio = new Audio(
              'https://upload.wikimedia.org/wikipedia/commons/6/61/Beep_400ms.ogg'
            );
            audio.play();
          }
        }
      }
    });
    console.log(subscription);
    return () => subscription.unsubscribe();
  }, [siteLocationId, doorId, lastKisiDoorUnlockEvent]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeString(getTimeString());
    }, 1000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (!gym) return;
    setKisiEventData(
      kisiEvents.slice(0, 10).map((event, idx, _) => ({
        id: event.id || 0,
        siteId: event.siteId || 0,
        siteLocationId: event.siteLocationId || 0,
        businessName: event.businessName || '',
        kisiDoorId: event.kisiDoorId || 0,
        kisiDoorName: event.kisiDoorName || '',
        doorOpenMemberEmail: event.doorOpenMemberEmail || '',
        doorOpenedTime: event.doorOpenedTime
          ? dayjs(event.doorOpenedTime || '').format('ddd, MMM D, YYYY h:mm:ss A')
          : '',
        memberId: event.memberId || 0,
        memberName: event.memberName || '',
        memberUniqueId: event.memberUniqueId || '',
        memberClientId: event.memberClientId || '',
        memberStatus: event.memberStatus || '',
        memberAccountBalance: event.memberAccountBalance || 0,
        memberNote: event.memberNote || '',
        createdDate: event.createdDate ? dayjs(event.createdDate || '').format('DD MMM YYYY') : '',
        createdBy: event.createdBy || 0,
        lastModifiedBy: event.lastModifiedBy || 0,
        lastModifiedDate: event.lastModifiedDate
          ? dayjs(event.lastModifiedDate || '').format('DD MMM YYYY')
          : ''
      }))
    );
  }, [kisiEvents, gym, memberLinked]);

  const toast = useRef(null);

  useEffect(() => {
    if (!linkMemberVariables) return;
    console.log(linkMemberVariables);
    confirm1(linkMemberVariables.buttonEvent);
  }, [linkMemberVariables]);

  const linkMember = async () => {
    const response = await (API.graphql({
      query: linkMemberToEvent,
      variables: {
        memberId: linkMemberVariables.memberId || 0,
        eventId: linkMemberVariables.eventId || 0
      }
    }) as Promise<{
      data: {
        linkMemberToEvent: { linkMemberToEvent: number };
      };
    }>);
    console.log({ response });
    if (response && response.data.linkMemberToEvent && linkMemberVariables.eventId !== 0) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'You have linked this member to the event',
        life: 3000
      });
      setTimeout(() => {
        setMemberLinked(true);
        setEventEmail('');
        setIsLinkingDialogVisible(false);
        fullScreenHandle.enter();
      }, 3000);
    } else {
      toast.current.show({
        severity: 'fail',
        summary: 'Failure',
        detail: 'Failed linking this member to the event',
        life: 3000
      });
      setEventEmail('');
      setIsLinkingDialogVisible(false);
      fullScreenHandle.enter();
    }
  };

  const accept = async () => {
    await linkMember();
  };

  const reject = () => {
    toast.current.show({
      severity: 'warn',
      summary: 'Cancelled',
      detail: 'You have cancelled this event link',
      life: 3000
    });
    setTimeout(() => {
      setEventEmail('');
      setIsLinkingDialogVisible(false);
      fullScreenHandle.enter();
    }, 3000);
  };

  const confirm1 = (event) => {
    console.log({ linkMemberVariables });
    confirmPopup({
      target: event,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject
    });
  };

  return (
    <div>
      <DialogBox
        dialogVisible={isLinkingDialogVisible}
        variant={DialogBoxVariants.xl}
        dialogDismissableMask={true}
        onHideCallback={() => {
          setEventEmail('');
          setIsLinkingDialogVisible(false);
          fullScreenHandle.enter();
        }}
        dialogFooter={<LinkingDialogFooter />}
        dialogClosable={false}
        dialogHeader={t(
          'EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.DUPLICATE_MEMBER_HEADER'
        )}
        content={
          unlinkedDoorOpenEvent.length > 0 ? (
            <div className="h-full w-full">
              <Toast ref={toast} />
              <ConfirmPopup />
              <div className="flex m-1">
                <p className="text-sm lg:text-base xl:text-base 2xl:text-xl 3xl:text-2xl font-bold">
                  {t('EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.DOOR_NAME')}
                </p>
                <p className="text-sm lg:text-base xl:text-base 2xl:text-xl 3xl:text-2xl">
                  {unlinkedDoorOpenEvent[0].kisiDoorName}
                </p>
              </div>
              <div className="flex m-1">
                <p className="text-sm lg:text-base xl:text-base 2xl:text-xl 3xl:text-2xl font-bold">
                  {t(
                    'EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.KISI_DOOR_OPEN_TIME_HEADER'
                  )}
                </p>
                <p className="text-sm lg:text-base xl:text-base 2xl:text-xl 3xl:text-2xl">
                  {unlinkedDoorOpenEvent[0].doorOpenedTime}
                </p>
              </div>
              <div className="flex m-1">
                <p className="text-sm lg:text-base xl:text-base 2xl:text-xl 3xl:text-2xl font-bold">
                  {t('EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.MEMBERS_HEADER')}
                </p>
              </div>
              <table className="h-full w-full table-auto text-center text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl">
                <thead>
                  <tr>
                    {LINKABLE_MEMBERS_TABLE_HEAD.map((head) => (
                      <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-100 p-4">
                        <p
                          color="blue-gray"
                          className="font-normal leading-none opacity-70 text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl">
                          {head}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {kisiEventMembersData.map(({ id, name, uniqueId, clientId, email }, index) => {
                    const isLast = index === kisiEventMembersData.length - 1;
                    const events = isLast
                      ? 'text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl'
                      : 'p-2.5 border-b border-blue-gray-50 text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl';

                    return (
                      <tr key={id}>
                        <td className={events}>
                          <p
                            color="blue-gray"
                            className="text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal">
                            {name}
                          </p>
                        </td>
                        <td className={`${events} bg-blue-gray-50/50`}>
                          <p
                            color="blue-gray"
                            className="text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal">
                            {uniqueId}
                          </p>
                        </td>
                        <td className={events}>
                          <p
                            color="blue-gray"
                            className="text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal">
                            {clientId}
                          </p>
                        </td>
                        <td className={events}>
                          <p
                            color="blue-gray"
                            className="text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal">
                            {email}
                          </p>
                        </td>
                        <td className={events}>
                          <button
                            type="button"
                            onClick={(e) => {
                              const linkMember = {
                                memberId: id,
                                eventId: unlinkedDoorOpenEvent[0].id,
                                buttonEvent: e.currentTarget
                              };
                              setLinkMemberVariables(linkMember);
                            }}
                            className="select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 p-2 text-center align-middle font-sans text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl  font-bold text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-1">
                            {t(
                              'EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.SELECT_MEMBER_BUTTON_LABEL'
                            )}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div>{t('EMPLOYEE_DASHBOARD_PAGE.KISI_UNLINKED_EVENT_TABLE.EVENT_NOT_FOUND')}</div>
          )
        }
      />
      <FullScreen handle={fullScreenHandle}>
        {siteLocationId && gym ? (
          <div className={`bg-[#ffffff] text-slate-900 w-full h-full`}>
            <div className={`bg-[#ffffff] text-slate-900 w-full h-full`}>
              {!fullScreenHandle.active && (
                <button
                  type="button"
                  onClick={() => fullScreenHandle.enter()}
                  className="table-cell p-4 text-center bg-[#ffffff] hover:cursor-pointer w-full">
                  {t('EMPLOYEE_DASHBOARD_PAGE.ENTER_FULL_SCREEN')}
                </button>
              )}

              <div className="h-svh w-full">
                <div className="h-[15vh] flex flex-col justify-evenly">
                  <div className="flex flex-row justify-center items-center  bg-[#ffffff]">
                    {gym.imageUrl ? (
                      <img
                        src={gym.imageUrl}
                        className="rounded-full w-12 md:w-14 lg:w-16 xl:w-20 2xl:w-24 h-12 md:h-14 lg:h-16 xl:h-20 2xl:h-24 object-cover p-0.5 lg:p-1 xl:p-1.5 2xl:p-2"
                        alt="resource logo"
                      />
                    ) : (
                      <Avatar size="custom" label={gym.gymName[0]} width={200} height={200} />
                    )}
                    <p className="text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl font-bold">
                      {gym.gymName}
                    </p>
                  </div>
                  <div className="flex flex-row gap-4 justify-center items-center bg-[#000000]">
                    <p className="w-full text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl text-slate-100 text-center">
                      {timeString}
                    </p>
                  </div>
                </div>
                {isEventsLoading ? (
                  <Loader shape="table" />
                ) : (
                  <div className="h-[85vh] w-full">
                    {kisiEventData.length > 0 ? (
                      <div className="grid grid-cols-9 divide-x h-[85vh]">
                        <div className="col-span-4 flex flex-col flex-1 items-center h-[85vh]">
                          <CarouselItem
                            kisiEvent={lastKisiDoorUnlockEvent}
                            fetching={isFetching}
                            tablePropsSubscriptions={memberDetails}
                            tablePropsBookings={memberBookingDetails}
                            t={t}
                          />
                        </div>
                        <div className="col-span-5 flex flex-col h-full w-full object-scale-down justify-start">
                          <table
                            className={`${
                              kisiEventData.length < 5
                                ? 'h-1/3'
                                : kisiEventData.length < 8
                                ? 'h-2/3'
                                : 'h-full'
                            } w-full text-center table-auto break-normal`}>
                            <thead>
                              <tr>
                                {DOOR_EVENTS_TABLE_HEAD.map((head) => (
                                  <th
                                    key={head}
                                    className="border-b border-blue-gray-100 bg-blue-gray-100 p-1.5 lg:p-2 xl:p-3 2xl:p-4 3xl:p-6 text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl">
                                    <p
                                      color="blue-gray"
                                      className="text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-none opacity-70">
                                      {head}
                                    </p>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {kisiEventData.map(
                                (
                                  {
                                    id,
                                    kisiDoorName,
                                    doorOpenMemberEmail,
                                    doorOpenedTime,
                                    memberName,
                                    memberClientId
                                  },
                                  index
                                ) => {
                                  const isLast = index === kisiEventData.length - 1;
                                  const events = isLast ? '' : 'border-b border-blue-gray-50';

                                  return (
                                    <tr key={id} className={`${events} font-normal`}>
                                      <td className={`${events} font-normal`}>
                                        <p
                                          color="blue-gray"
                                          className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal`}>
                                          {kisiDoorName}
                                        </p>
                                      </td>
                                      <td className={`${events} font-normal`}>
                                        <p
                                          color="blue-gray"
                                          className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-snug`}>
                                          {doorOpenedTime}
                                        </p>
                                      </td>
                                      <td className={`${events} font-normal`}>
                                        {memberName ? (
                                          <p
                                            color="blue-gray"
                                            className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-snug`}>
                                            {memberName}
                                          </p>
                                        ) : (
                                          <p
                                            color="blue-gray"
                                            className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-snug`}>
                                            {null}
                                          </p>
                                        )}
                                      </td>
                                      <td className={`${events} font-normal`}>
                                        {memberClientId ? (
                                          <button
                                            className="select-none items-center p-0.5 lg:p-1 xl:p-1.5 2xl:p-2 text-center align-middle font-sans leading-snug font-normal  hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl"
                                            onClick={() => {
                                              console.log(kisiEventData);
                                              setLastKisiDoorUnlockEvent(
                                                kisiEventData.filter((x) => x.id === id)[0]
                                              );
                                            }}>
                                            <p
                                              color="blue-gray"
                                              className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-snug`}>
                                              {memberClientId}
                                            </p>
                                          </button>
                                        ) : (
                                          <button
                                            className="select-none items-center p-0.5 lg:p-1 xl:p-1.5 2xl:p-2 rounded-lg bg-gray-900 text-center align-middle font-sans leading-snug font-normal text-white shadow-md shadow-gray-900/5 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl"
                                            onClick={() => {
                                              setUnlinkedDoorOpenEvent(
                                                kisiEventData.filter((x) => {
                                                  return x.id == id;
                                                })
                                              );
                                              setEventEmail(doorOpenMemberEmail);
                                            }}>
                                            {t(
                                              'EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.BUTTON_LINK_MEMBER_LABEL'
                                            )}
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <div className="h-full flex align-items-center">
                          <p className="w-full text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl font-semibold m-5">
                            {t(
                              'EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.NO_RECORDED_EVENTS_LABEL'
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </FullScreen>
    </div>
  );
};

const CarouselItem = ({
  kisiEvent,
  fetching,
  tablePropsSubscriptions,
  tablePropsBookings,
  t
}: {
  kisiEvent: LastKisiEvent;
  fetching: boolean;
  tablePropsSubscriptions: MemberDetails[];
  tablePropsBookings: MemberBookingDetails[];
  t: any;
}) => (
  <div className="flex flex-1 flex-col gap-1 lg:gap-1.5 xl:gap-2 2xl:gap-2.5 3xl:gap-3 h-[85vh] w-full">
    {kisiEvent ? (
      <div className="flex flex-1 flex-col gap-1 lg:gap-1.5 xl:gap-2 2xl:gap-2.5 3xl:gap-3  h-full my-1 lg:my-2 xl:my-3 2xl:my-4 3xl:my-5">
        <p className="text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl font-semibold text-center">
          {kisiEvent.kisiDoorName}
        </p>
        <p className="text-xs lg:text-sm xl:text-base 2xl:text-lg 3xl:text-xl font-normal text-center">
          {kisiEvent.doorOpenedTime}
        </p>
        <span className="text-center self-center w-full">
          {kisiEvent.businessName && kisiEvent.memberUniqueId ? (
            <div className="grid grid-cols-8 divide-x h-full">
              <div className="col-span-4 flex flex-col flex-1 items-center">
                <img
                  src={`https://clients-content.mindbodyonline.com/studios/${kisiEvent.businessName}/clients/${kisiEvent.memberUniqueId}_large.jpg`}
                  className="rounded-full w-16 lg:w-24 xl:w-32 2xl:w-40 3xl:w-48 h-16 lg:h-24 xl:h-32 2xl:h-40 3xl:h-48 object-cover ring-2 ring-[#000000] "
                  alt="memberImg"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = memberPlaceholder;
                  }}
                />
              </div>
              <div className="col-span-4 flex flex-col flex-1 items-center justify-center h-16 lg:h-24 xl:h-32 2xl:h-40 3xl:h-48">
                <p className="text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl font-semibold">
                  {kisiEvent.memberName}
                </p>
                <p className="text-xs lg:text-sm xl:text-base 2xl:text-lg 3xl:text-xl font-semibold text-center">
                  {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.ACCOUNT_BALANCE_LABEL')}
                  {kisiEvent.memberAccountBalance}
                </p>
                <p className="text-xs lg:text-sm xl:text-base 2xl:text-lg 3xl:text-xl font-semibold text-center">
                  {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.STATUS_LABEL')}
                  {kisiEvent.memberStatus}
                </p>
              </div>
            </div>
          ) : (
            <img
              src={memberPlaceholder}
              className="rounded-full w-16 lg:w-24 xl:w-32 2xl:w-40 3xl:w-48 h-16 lg:h-24 xl:h-32 2xl:h-40 3xl:h-48  object-cover ring-2 ring-[#000000] "
              alt="memberImg"
            />
          )}
        </span>
        {kisiEvent.memberId ? (
          <div className="grid-rows-9 h-full mt-2 lg:mt-3 xl:mt-6 2xl:mt-8 3xl:mt-10">
            <p className="row-span-3 text-xs lg:text-sm xl:text-base 2xl:text-lg3xl:text-2xl font-normal text-center overflow-auto h-[10vh]">
              <b className="text-xs lg:text-sm xl:text-base 2xl:text-lg3xl:text-2xl text-center overflow-auto ">
                {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.NOTES_LABEL')}
              </b>
              {kisiEvent.memberNote}
            </p>
            <p className="text-xs lg:text-sm xl:text-base 2xl:text-lg3xl:text-2xl font-normal text-center mt-2 lg:mt-2.5 xl:mt-4 2xl:mt-5 3xl:mt-6 ">
              <b className="text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl text-center">
                {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.PRICING_OPTIONS_TABLE_HEADER')}
              </b>
            </p>
            <p className="row-span-3 text-xs lg:text-sm xl:text-base 2xl:text-lg3xl:text-2xl font-normal text-center overflow-auto h-[16vh] lg:h-[17vh] xl:[18vh] 2xl:[18vh] 3xl:h-[20vh]">
              {!fetching ? (
                <table className="text-sm lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl font-normal text-center overflow-auto h-[16vh] lg:h-[17vh] xl:[18vh] 2xl:[18vh] 3xl:h-[20vh] w-full">
                  <thead>
                    <tr>
                      {[
                        t(
                          'EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.PRICING_OPTIONS_TABLE_MEMBERSHIP_HEADER'
                        ),
                        t(
                          'EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.PRICING_OPTIONS_TABLE_VALID_FROM_HEADER'
                        ),
                        t(
                          'EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.PRICING_OPTIONS_TABLE_VALID_TO_HEADER'
                        ),
                        t(
                          'EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.PRICING_OPTIONS_TABLE_REMAINING_SESSIONS_HEADER'
                        )
                      ].map((head) => (
                        <th
                          key={head}
                          className="border-b border-blue-gray-100 bg-blue-gray-100 p-1.5 lg:p-2 xl:p-3 2xl:p-4 3xl:p-6 text-xs lg:text-xs xl:text-base 2xl:text-base 3xl:text-xl">
                          <p
                            color="blue-gray"
                            className="text-xs lg:text-xs xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-none opacity-70">
                            {head}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tablePropsSubscriptions.map(
                      ({ membership, validFrom, validTo, remainingSessions }, index) => {
                        const isLast = index === tablePropsSubscriptions.length - 1;
                        const events = isLast ? '' : 'border-b border-blue-gray-50';

                        return (
                          <tr key={index} className={`${events} font-normal`}>
                            <td className={`${events} font-normal`}>
                              <p
                                color="blue-gray"
                                className={`text-xs lg:text-xs xl:text-base 2xl:text-base 3xl:text-xl font-normal`}>
                                {membership}
                              </p>
                            </td>
                            <td className={`${events} font-normal`}>
                              <p
                                color="blue-gray"
                                className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal`}>
                                {validFrom}
                              </p>
                            </td>
                            <td className={`${events} font-normal`}>
                              <p
                                color="blue-gray"
                                className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-snug`}>
                                {validTo}
                              </p>
                            </td>
                            <td className={`${events} font-normal`}>
                              <p
                                color="blue-gray"
                                className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-snug`}>
                                {remainingSessions}
                              </p>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              ) : (
                <p>
                  {t(
                    'EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.PRICING_OPTIONS_TABLE_EMPTY_STATE'
                  )}
                </p>
              )}
            </p>
            <p className="text-sm lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl font-normal text-center mt-2 lg:mt-2.5 xl:mt-4 2xl:mt-5 3xl:mt-6 ">
              <b className="text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl text-center">
                {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.BOOKINGS_TABLE_HEADER')}
              </b>
            </p>
            <p className="row-span-3 text-sm lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl font-normal text-center overflow-auto h-[16vh] lg:h-[17vh] xl:[18vh] 2xl:[18vh] 3xl:h-[20vh]">
              {!fetching ? (
                <table className="text-sm lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl font-normal text-center overflow-auto h-[16vh] lg:h-[17vh] xl:[18vh] 2xl:[18vh] 3xl:h-[20vh] w-full">
                  <thead>
                    <tr>
                      {[
                        t(
                          'EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.BOOKINGS_TABLE_NAME_HEADER'
                        ),
                        t(
                          'EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.BOOKINGS_TABLE_START_DATE_HEADER'
                        ),
                        t(
                          'EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.BOOKINGS_TABLE_END_DATE_HEADER'
                        )
                      ].map((head) => (
                        <th
                          key={head}
                          className="border-b border-blue-gray-100 bg-blue-gray-100 p-1.5 lg:p-2 xl:p-3 2xl:p-4 3xl:p-6 text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl">
                          <p
                            color="blue-gray"
                            className="text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-none opacity-70">
                            {head}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tablePropsBookings.map(({ booking, startDateTime, endDateTime }, index) => {
                      const isLast = index === tablePropsBookings.length - 1;
                      const events = isLast ? '' : 'border-b border-blue-gray-50';

                      return (
                        <tr key={index} className={`${events} font-normal`}>
                          <td className={`${events} font-normal`}>
                            <p
                              color="blue-gray"
                              className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal`}>
                              {booking}
                            </p>
                          </td>
                          <td className={`${events} font-normal`}>
                            <p
                              color="blue-gray"
                              className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-snug`}>
                              {startDateTime}
                            </p>
                          </td>
                          <td className={`${events} font-normal`}>
                            <p
                              color="blue-gray"
                              className={`text-xs lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl font-normal leading-snug`}>
                              {endDateTime}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>
                  {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DASHBOARD_CAROUSEL.BOOKINGS_TABLE_EMPTY_STATE')}
                </p>
              )}
            </p>
          </div>
        ) : (
          /* <div className="flex flex-1 flex-col gap-1 lg:gap-1.5 xl:gap-3 2xl:gap-3.5 3xl:gap-5 h-[5vh] my-1 lg:my-2 xl:my-3 2xl:my-4 3xl:my-5">
              
            </div>
            <div className="flex flex-1 flex-col gap-1 lg:gap-1.5 xl:gap-3 2xl:gap-3.5 3xl:gap-5 h-[5vh] my-1 lg:my-2 xl:my-3 2xl:my-4 3xl:my-5">
              <p className=" w-full text-sm lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl font-normal text-center overflow-hidden  h-28 lg:h-32 xl:h-40 2xl:h-48 3xl:h-64 ">
                {fetching ? <Loader shape="table" /> : <Table {...tablePropsBookings} />}
              </p>
              <br />
            </div> */
          <p className="text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl font-semibold text-center">
            {kisiEvent.doorOpenMemberEmail}
          </p>
        )}
      </div>
    ) : (
      <div className="text-center">
        <div className="h-full flex align-items-center">
          <p className="w-full text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl font-normal m-5">
            {i18n.t('EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.NO_RECORDED_EVENTS_FOR_DOOR_LABEL')}
          </p>
        </div>
      </div>
    )}
  </div>
);

const DoorUnlockEventsDashboardPage = () => {
  return <MainColumn />;
};

export default DoorUnlockEventsDashboardPage;
