/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClassPortalData = /* GraphQL */ `
  query GetClassPortalData($siteLocationId: Int!) {
    getClassPortalData(siteLocationId: $siteLocationId) {
      id
      importedId
      name
      siteLocationId
      resourceId
      staffId
      startDateTime
      endDateTime
      maxCapacity
      totalBooked
      resourceMindbodyId
      resourceName
      resourceImageUrl
      staffMindbodyId
      staffName
      staffImageUrl
      imageUrl
    }
  }
`;
export const getItems = /* GraphQL */ `
  query GetItems {
    getItems {
      id
      type
      price
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: String!) {
    getItem(id: $id) {
      id
      type
      price
    }
  }
`;
export const getMembershipTypes = /* GraphQL */ `
  query GetMembershipTypes {
    getMembershipTypes {
      id
      name
      isDeleted
    }
  }
`;
export const getMemberships = /* GraphQL */ `
  query GetMemberships(
    $siteId: Int!
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
    $isMindbody: Boolean!
  ) {
    getMemberships(
      siteId: $siteId
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      isMindbody: $isMindbody
    ) {
      totalRecords {
        totalRecords
      }
      items {
        id
        siteId
        membershipName
        membershipTypeId
        membershipType
        importedId
        isImported
        validityPeriod
        isMinusCreditBalanceApplied
        status
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        isDeleted
        firstName
        lastName
        program
        siteLocations
        hasRelatedAccessCategory
        scheduleType
        isContractOnly
      }
    }
  }
`;
export const getSiteLocationMemberships = /* GraphQL */ `
  query GetSiteLocationMemberships($membershipId: Int) {
    getSiteLocationMemberships(membershipId: $membershipId) {
      siteLocationMembershipId
      siteLocationId
      siteLocationName
      siteLocationStatus
      siteLocationMembershipStatus
      isDeleted
    }
  }
`;
export const getSites = /* GraphQL */ `
  query GetSites {
    getSites {
      id
      providerId
      customerSiteId
      customerName
      customerContactPerson
      customerPhone
      customerEmail
      customerAddress
      membershipsImported
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: String) {
    getSite(id: $id) {
      id
      providerId
      customerSiteId
      customerName
      customerContactPerson
      customerPhone
      customerEmail
      customerAddress
      membershipsImported
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const getSiteLocations = /* GraphQL */ `
  query GetSiteLocations {
    getSiteLocations {
      id
      siteId
      siteLocationIdApi
      locationName
      locationContactPerson
      locationPhone
      locationEmail
      locationAddress
      latitude
      longitude
      freePassId
      trailMembershipId
      staffAccessMembershipId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
      isLockerEnabled
    }
  }
`;
export const getSiteLocation = /* GraphQL */ `
  query GetSiteLocation($id: String) {
    getSiteLocation(id: $id) {
      id
      siteId
      siteLocationIdApi
      locationName
      locationContactPerson
      locationPhone
      locationEmail
      locationAddress
      latitude
      longitude
      freePassId
      trailMembershipId
      staffAccessMembershipId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
      isLockerEnabled
    }
  }
`;
export const getGymChains = /* GraphQL */ `
  query GetGymChains(
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
    $userId: Int!
  ) {
    getGymChains(
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      userId: $userId
    ) {
      totalRecords {
        totalRecords
      }
      items {
        id
        providerId
        customerSiteId
        customerName
        customerContactPerson
        customerPhone
        supportPhone
        maximumNegativeBalance
        customerEmail
        customerAddress
        status
        membershipsImported
        createdBy
        lastModifiedBy
        createdDate
        lastModifiedDate
        lastImportedDate
        imageUrl
        isDeleted
        arxReferenceId
        providerConnected
        isSodvinCompany
        gymCount
        allowConvertingAccessCards
        processBookings
        processSales
        processAppointments
        fetchAccountBalance
        kisiApiKey
        kisiNightlySyncApiKey
      }
    }
  }
`;
export const getGyms = /* GraphQL */ `
  query GetGyms(
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
    $userId: Int!
  ) {
    getGyms(
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      userId: $userId
    ) {
      totalRecords {
        totalRecords
      }
      items {
        gymChainName
        gymChainId
        gymId
        gymIdApi
        gymName
        contactPerson
        phoneNumber
        email
        address
        status
        createdDate
        createdBy
        lastModifiedDate
        lastImportedDate
        lastModifiedBy
        imageUrl
        latitude
        longitude
        trialMembershipId
        staffMembershipId
        freePassId
        handbackTime
        defaultLocationDetectionMethod
        primaryAccessMethod
        externalBookingsLink
        facebookLink
        twitterLink
        instagramLink
        gracePeriodWhenDeclined
        accessPerDayWhenDeclined
        gracePeriodWhenExpired
        accessPerDayWhenExpired
        gracePeriodWhenSuspended
        accessPerDayWhenSuspended
        isMobileCredentialAllowed
        websiteLink
        city
        membershipOnSignup
        membershipForVisits
        syncAllVisitsWithGenericSubscription
        isDeleted
        signUpUrl
        arxReferenceId
        sodvinCompanyId
        providerId
        allowConvertingAccessCards
        cardType
        addArrivalAuto
        addVisitAuto
        sessionCheckTimeLimit
        sessionCheckTimeLimitUpper
        timeZone
        prioratizeClasses
        blockTime
        markArrivalFirst
        openWithoutArrivalMarked
        markMultipleAttendanceAuto
        markArrivalsWhenThereAreVisits
      }
    }
  }
`;
export const getWebUsers = /* GraphQL */ `
  query GetWebUsers($userId: Int!) {
    getWebUsers(userId: $userId) {
      totalRecords {
        totalRecords
      }
      items {
        userId
        firstName
        lastName
        email
        phone
        createdDate
        createdBy
        lastModifiedDate
        lastModifiedBy
        isActive
        memberId
        roleId
        profilePicturePath
        roleName
        siteId
        siteName
        siteLocationId
        siteLocationName
        language
        memberArxReferenceId
      }
    }
  }
`;
export const getMobileUsers = /* GraphQL */ `
  query GetMobileUsers(
    $userId: Int!
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
  ) {
    getMobileUsers(
      userId: $userId
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      totalRecords {
        totalRecords
      }
      items {
        id
        firstName
        lastName
        email
        phone
        address
        createdDate
        createdBy
        lastModifiedBy
        lastModifiedDate
        status
        isMobileAppDisabled
        isJustTapRevoked
        isMoreInfoRequested
        imageUrl
        language
        defaultLocationDetectionMethod
        memberId
        memberName
      }
    }
  }
`;
export const getUserByPhone = /* GraphQL */ `
  query GetUserByPhone($phone: String!) {
    getUserByPhone(phone: $phone) {
      id
      username
      firstName
      lastName
      phone
      email
      isActive
      language
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      appVersion
      profilePicturePath
      isProfilePicVerified
      isMobileAppUser
      isAdministrator
      isDeleted
      isSystemUser
      isMobileAppDisabled
      isJustTapRevoked
      defaultLocationDetectionMethod
      isMoreInfoRequested
      memberId
    }
  }
`;
export const getActiveDevice = /* GraphQL */ `
  query GetActiveDevice($userId: Int!) {
    getActiveDevice(userId: $userId) {
      id
      userId
      appVersion
      macAddress
      uniqueId
      model
      manufacturer
      isActive
      isCameraPresent
      locale
      type
      timeZone
      signedUpStatus
      country
      baseOs
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const importSite = /* GraphQL */ `
  query ImportSite($siteId: Int!, $provider: String!, $checkWithDB: Boolean) {
    importSite(
      siteId: $siteId
      provider: $provider
      checkWithDB: $checkWithDB
    ) {
      PaginationResponse {
        RequestedLimit
        RequestedOffset
        PageSize
        TotalResults
      }
      Sites {
        AcceptsAmericanExpress
        AcceptsDiscover
        AcceptsMasterCard
        AcceptsVisa
        AllowsDashboardAccess
        ContactEmail
        Description
        Id
        LogoUrl
        Name
        PageColor1
        PageColor2
        PageColor3
        PageColor4
        PricingLevel
        SmsPackageEnabled
        TaxInclusivePrices
        CurrencyIsoCode
        CountryCode
        TimeZone
        AcceptsDirectDebit
      }
      error
      errorMsg
    }
  }
`;
export const getGymsOfGymChain = /* GraphQL */ `
  query GetGymsOfGymChain(
    $id: Int!
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
    $userId: Int!
  ) {
    getGymsOfGymChain(
      id: $id
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
      userId: $userId
    ) {
      totalRecords {
        totalRecords
      }
      items {
        gymChainName
        gymChainId
        gymId
        gymIdApi
        gymName
        contactPerson
        phoneNumber
        email
        address
        status
        createdDate
        createdBy
        lastModifiedDate
        lastImportedDate
        lastModifiedBy
        imageUrl
        latitude
        longitude
        trialMembershipId
        staffMembershipId
        freePassId
        handbackTime
        defaultLocationDetectionMethod
        primaryAccessMethod
        externalBookingsLink
        facebookLink
        twitterLink
        instagramLink
        gracePeriodWhenDeclined
        accessPerDayWhenDeclined
        gracePeriodWhenExpired
        accessPerDayWhenExpired
        gracePeriodWhenSuspended
        accessPerDayWhenSuspended
        isMobileCredentialAllowed
        websiteLink
        city
        membershipOnSignup
        membershipForVisits
        syncAllVisitsWithGenericSubscription
        isDeleted
        signUpUrl
        arxReferenceId
        sodvinCompanyId
        providerId
        allowConvertingAccessCards
        cardType
        addArrivalAuto
        addVisitAuto
        sessionCheckTimeLimit
        sessionCheckTimeLimitUpper
        timeZone
        prioratizeClasses
        blockTime
        markArrivalFirst
        openWithoutArrivalMarked
        markMultipleAttendanceAuto
        markArrivalsWhenThereAreVisits
      }
    }
  }
`;
export const uploadImageUrlResolver = /* GraphQL */ `
  query UploadImageUrlResolver($imageName: String!) {
    uploadImageUrlResolver(imageName: $imageName) {
      url
      uploadUrl
    }
  }
`;
export const getDefaultLocationDetectionMethods = /* GraphQL */ `
  query GetDefaultLocationDetectionMethods($userId: Int!) {
    getDefaultLocationDetectionMethods(userId: $userId) {
      gymId
      gymDefined
      userDefined
    }
  }
`;
export const getPlatformSettings = /* GraphQL */ `
  query GetPlatformSettings {
    getPlatformSettings {
      id
      setting
      value
      isDeleted
    }
  }
`;
export const importLocation = /* GraphQL */ `
  query ImportLocation($locationId: Int!, $provider: String!, $siteId: Int!) {
    importLocation(
      locationId: $locationId
      provider: $provider
      siteId: $siteId
    ) {
      AdditionalImageURLs
      Address
      Address2
      BusinessDescription
      City
      Description
      HasClasses
      Id
      Latitude
      Longitude
      Name
      Phone
      PhoneExtension
      PostalCode
      SiteID
      StateProvCode
    }
  }
`;
export const importKisiDoor = /* GraphQL */ `
  query ImportKisiDoor($siteId: Int!, $doorId: Int!) {
    importKisiDoor(siteId: $siteId, doorId: $doorId) {
      id
      name
      placeId
      placeName
    }
  }
`;
export const getGymByApiId = /* GraphQL */ `
  query GetGymByApiId($gymChainId: Int!, $gymApiId: Int!) {
    getGymByApiId(gymChainId: $gymChainId, gymApiId: $gymApiId) {
      gymChainName
      gymChainId
      gymId
      gymIdApi
      gymName
      contactPerson
      phoneNumber
      email
      address
      status
      createdDate
      createdBy
      lastModifiedDate
      lastImportedDate
      lastModifiedBy
      imageUrl
      latitude
      longitude
      trialMembershipId
      staffMembershipId
      freePassId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      isMobileCredentialAllowed
      websiteLink
      city
      membershipOnSignup
      membershipForVisits
      syncAllVisitsWithGenericSubscription
      isDeleted
      signUpUrl
      arxReferenceId
      sodvinCompanyId
      providerId
      allowConvertingAccessCards
      cardType
      addArrivalAuto
      addVisitAuto
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      timeZone
      prioratizeClasses
      blockTime
      markArrivalFirst
      openWithoutArrivalMarked
      markMultipleAttendanceAuto
      markArrivalsWhenThereAreVisits
    }
  }
`;
export const isGymDeletable = /* GraphQL */ `
  query IsGymDeletable($siteLocationId: Int!) {
    isGymDeletable(siteLocationId: $siteLocationId) {
      id
      rowCount
      tableName
    }
  }
`;
export const isGymChainDeletable = /* GraphQL */ `
  query IsGymChainDeletable($siteId: Int!) {
    isGymChainDeletable(siteId: $siteId) {
      id
      rowCount
      tableName
    }
  }
`;
export const isMemberLinked = /* GraphQL */ `
  query IsMemberLinked($memberId: Int!) {
    isMemberLinked(memberId: $memberId) {
      rowCount
    }
  }
`;
export const getMemberUserLink = /* GraphQL */ `
  query GetMemberUserLink($memberId: Int!) {
    getMemberUserLink(memberId: $memberId) {
      totalRecords
    }
  }
`;
export const getAvailableHolidays = /* GraphQL */ `
  query GetAvailableHolidays(
    $locationId: Int!
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
  ) {
    getAvailableHolidays(
      locationId: $locationId
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      totalRecords {
        totalRecords
      }
      items {
        id
        locationId
        name
        isHoliday
        isGeneral
        validFromDateTime
        validToDateTime
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        status
        dayOfWeek
        isDeleted
        hourDetailId
        validFromDate
        validToDate
      }
    }
  }
`;
export const importMemberships = /* GraphQL */ `
  query ImportMemberships($siteId: Int!) {
    importMemberships(siteId: $siteId) {
      error
      errorType
      errorMsg
      memberships {
        id
        siteId
        importedId
        membershipName
        membershipTypeId
        isImported
        validityPeriod
        isMinusCreditBalanceApplied
        status
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        isDeleted
      }
    }
  }
`;
export const getAllMembershipsForSiteLocation = /* GraphQL */ `
  query GetAllMembershipsForSiteLocation($siteLocationId: Int!) {
    getAllMembershipsForSiteLocation(siteLocationId: $siteLocationId) {
      siteLocationMembershipId
      siteLocationId
      siteLocationImportedId
      membershipId
      membershipName
      membershipTypeName
      validityPeriod
      isImported
      importedId
      status
      createdDate
      program
      scheduleType
    }
  }
`;
export const getMembershipsForSiteLocation = /* GraphQL */ `
  query GetMembershipsForSiteLocation(
    $siteLocationId: Int!
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
  ) {
    getMembershipsForSiteLocation(
      siteLocationId: $siteLocationId
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      totalRecords {
        totalRecords
      }
      items {
        siteLocationMembershipId
        siteLocationId
        siteLocationImportedId
        membershipId
        membershipName
        membershipTypeName
        validityPeriod
        isImported
        importedId
        status
        createdDate
        program
        scheduleType
      }
    }
  }
`;
export const getAllAvailableHolidays = /* GraphQL */ `
  query GetAllAvailableHolidays($locationId: Int!) {
    getAllAvailableHolidays(locationId: $locationId) {
      totalRecords {
        totalRecords
      }
      items {
        id
        locationId
        name
        isHoliday
        isGeneral
        validFromDateTime
        validToDateTime
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        status
        dayOfWeek
        isDeleted
        hourDetailId
        validFromDate
        validToDate
      }
    }
  }
`;
export const getAvailableLocationOpeningHours = /* GraphQL */ `
  query GetAvailableLocationOpeningHours($locationId: Int!) {
    getAvailableLocationOpeningHours(locationId: $locationId) {
      totalRecords {
        totalRecords
      }
      items {
        id
        locationId
        openingHourId
        startDateTime
        endDateTime
        dayOfWeek
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        isHoliday
        name
        status
        validFromDate
        validToDate
      }
    }
  }
`;
export const gymSearch = /* GraphQL */ `
  query GymSearch($searchQuery: String!, $pageIndex: Int!, $pageSize: Int!) {
    gymSearch(
      searchQuery: $searchQuery
      pageIndex: $pageIndex
      pageSize: $pageSize
    ) {
      gymId
      gymName
      gymLogo
      gymAddress
      gymCity
      freePassId
      trialMembershipId
      membershipOnSignup
      membershipForVisits
      syncAllVisitsWithGenericSubscription
      providerId
    }
  }
`;
export const getGymsInUserVicinity = /* GraphQL */ `
  query GetGymsInUserVicinity(
    $latitude: Float!
    $longitude: Float!
    $radius: Int!
  ) {
    getGymsInUserVicinity(
      latitude: $latitude
      longitude: $longitude
      radius: $radius
    ) {
      gymChainName
      gymChainId
      gymId
      gymIdApi
      gymName
      contactPerson
      phoneNumber
      email
      address
      status
      createdDate
      createdBy
      lastModifiedDate
      lastImportedDate
      lastModifiedBy
      imageUrl
      latitude
      longitude
      trialMembershipId
      staffMembershipId
      freePassId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      isMobileCredentialAllowed
      websiteLink
      city
      membershipOnSignup
      membershipForVisits
      syncAllVisitsWithGenericSubscription
      isDeleted
      signUpUrl
      arxReferenceId
      sodvinCompanyId
      providerId
      allowConvertingAccessCards
      cardType
      addArrivalAuto
      addVisitAuto
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      timeZone
      prioratizeClasses
      blockTime
      markArrivalFirst
      openWithoutArrivalMarked
      markMultipleAttendanceAuto
      markArrivalsWhenThereAreVisits
    }
  }
`;
export const getGymChainById = /* GraphQL */ `
  query GetGymChainById($id: Int!) {
    getGymChainById(id: $id) {
      id
      providerId
      customerSiteId
      customerName
      customerContactPerson
      customerPhone
      supportPhone
      maximumNegativeBalance
      customerEmail
      customerAddress
      status
      membershipsImported
      createdBy
      lastModifiedBy
      createdDate
      lastModifiedDate
      lastImportedDate
      imageUrl
      isDeleted
      arxReferenceId
      providerConnected
      isSodvinCompany
      gymCount
      allowConvertingAccessCards
      processBookings
      processSales
      processAppointments
      fetchAccountBalance
      kisiApiKey
      kisiNightlySyncApiKey
    }
  }
`;
export const getGymById = /* GraphQL */ `
  query GetGymById($id: Int!) {
    getGymById(id: $id) {
      gymChainName
      gymChainId
      gymId
      gymIdApi
      gymName
      contactPerson
      phoneNumber
      email
      address
      status
      createdDate
      createdBy
      lastModifiedDate
      lastImportedDate
      lastModifiedBy
      imageUrl
      latitude
      longitude
      trialMembershipId
      staffMembershipId
      freePassId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      isMobileCredentialAllowed
      websiteLink
      city
      membershipOnSignup
      membershipForVisits
      syncAllVisitsWithGenericSubscription
      isDeleted
      signUpUrl
      arxReferenceId
      sodvinCompanyId
      providerId
      allowConvertingAccessCards
      cardType
      addArrivalAuto
      addVisitAuto
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      timeZone
      prioratizeClasses
      blockTime
      markArrivalFirst
      openWithoutArrivalMarked
      markMultipleAttendanceAuto
      markArrivalsWhenThereAreVisits
    }
  }
`;
export const getLocationMembershipOpeningHour = /* GraphQL */ `
  query GetLocationMembershipOpeningHour(
    $openingHourId: Int!
    $isHoliday: Boolean!
  ) {
    getLocationMembershipOpeningHour(
      openingHourId: $openingHourId
      isHoliday: $isHoliday
    ) {
      id
      openingHourId
      membershipId
      name
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const getMembersForSiteLocation = /* GraphQL */ `
  query GetMembersForSiteLocation(
    $siteLocationId: Int!
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
  ) {
    getMembersForSiteLocation(
      siteLocationId: $siteLocationId
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      totalRecords {
        totalRecords
      }
      items {
        memberId
        uniqueId
        clientId
        accountBalance
        memberFirstName
        memberLastName
        isMobileAppDisabled
        isJustTapRevoked
        isStaffMember
        signedUpDate
        phoneNumber
        status
        createdDate
        createdBy
        lastModifiedDate
        lastModifiedBy
        expiredDate
        isBlocked
        defaultGymDetectionMethod
        email
        profilePicturePath
        lastSyncDate
        isTrial
        arxReferenceId
        userId
        linkedPhoneNumber
        language
        hasAccessCard
        hasForcedAccess
        hasLocationOverride
        hasManualAccess
        isFreepassMember
        memberLocationId
        flag
      }
    }
  }
`;
export const getMemberByMemberId = /* GraphQL */ `
  query GetMemberByMemberId(
    $siteLocationId: Int!
    $memberId: Int!
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
  ) {
    getMemberByMemberId(
      siteLocationId: $siteLocationId
      memberId: $memberId
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      totalRecords {
        totalRecords
      }
      items {
        memberId
        uniqueId
        clientId
        accountBalance
        memberFirstName
        memberLastName
        isMobileAppDisabled
        isJustTapRevoked
        isStaffMember
        signedUpDate
        phoneNumber
        status
        createdDate
        createdBy
        lastModifiedDate
        lastModifiedBy
        expiredDate
        isBlocked
        defaultGymDetectionMethod
        email
        profilePicturePath
        lastSyncDate
        isTrial
        arxReferenceId
        userId
        linkedPhoneNumber
        language
        hasAccessCard
        hasForcedAccess
        hasLocationOverride
        hasManualAccess
        isFreepassMember
        memberLocationId
        flag
      }
    }
  }
`;
export const getMemberDetails = /* GraphQL */ `
  query GetMemberDetails($memberId: Int!) {
    getMemberDetails(memberId: $memberId) {
      membership
      memberLocationMembershipId
      subscriptionDeleted
      validFrom
      validTo
      subscriptionCreatedDate
      subscriptionModifiedDate
      subscriptionIsSelected
      subscriptionStatus
      scheduleType
      remainingSessions
      siteLocationMembershipStatus
    }
  }
`;
export const getMemberBookingDetails = /* GraphQL */ `
  query GetMemberBookingDetails($memberId: Int!) {
    getMemberBookingDetails(memberId: $memberId) {
      booking
      importedId
      appointmentId
      isVisited
      startDateTime
      endDateTime
      createdDate
      lastModifiedDate
      membershipName
      siteLocation
      isDeleted
    }
  }
`;
export const isMembershipLinkedToGym = /* GraphQL */ `
  query IsMembershipLinkedToGym($membershipId: Int!) {
    isMembershipLinkedToGym(membershipId: $membershipId) {
      id
      rowCount
      tableName
    }
  }
`;
export const isSiteLocationMembershipLinked = /* GraphQL */ `
  query IsSiteLocationMembershipLinked($id: Int!, $membershipId: Int!) {
    isSiteLocationMembershipLinked(id: $id, membershipId: $membershipId) {
      id
      rowCount
      tableName
    }
  }
`;
export const getZonesForSiteLocation = /* GraphQL */ `
  query GetZonesForSiteLocation($siteLocationId: Int!) {
    getZonesForSiteLocation(siteLocationId: $siteLocationId) {
      items {
        id
        siteLocationId
        zoneName
        zoneDescription
        arxReferenceId
        status
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        locationAccessCategoryZoneId
        locationAccessCategoryId
        locationAccessCategoryName
        locationAccessCategoryGender
        locationAccessCategoryArxRefId
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getMembershipsForZone = /* GraphQL */ `
  query GetMembershipsForZone($locationZoneId: Int!) {
    getMembershipsForZone(locationZoneId: $locationZoneId) {
      id
      locationZoneId
      membershipId
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const getMemberAndSubscriptionsById = /* GraphQL */ `
  query GetMemberAndSubscriptionsById($memberId: Int!, $siteLocationId: Int!) {
    getMemberAndSubscriptionsById(
      memberId: $memberId
      siteLocationId: $siteLocationId
    ) {
      memberId
      uniqueId
      memberFirstName
      memberLastName
      phoneNumber
      email
      address
      profilePicturePath
      status
      membershipId
      membershipName
      membershipImportedId
      validFrom
      validTo
      memberStatus
      arxReferenceId
    }
  }
`;
export const isZoneLinked = /* GraphQL */ `
  query IsZoneLinked($locationZoneId: Int!) {
    isZoneLinked(locationZoneId: $locationZoneId) {
      count
    }
  }
`;
export const getDataUpdateStatus = /* GraphQL */ `
  query GetDataUpdateStatus($phone: String, $dialingPhone: String) {
    getDataUpdateStatus(phone: $phone, dialingPhone: $dialingPhone) {
      memberId
      lastSyncDate
      memberPhone
    }
  }
`;
export const getMemberStatusTypes = /* GraphQL */ `
  query GetMemberStatusTypes {
    getMemberStatusTypes {
      id
      status
    }
  }
`;
export const getAccessPointsForSiteLocation = /* GraphQL */ `
  query GetAccessPointsForSiteLocation($siteLocationId: Int!) {
    getAccessPointsForSiteLocation(siteLocationId: $siteLocationId) {
      items {
        id
        siteLocationId
        locationZoneId
        locationZoneName
        zoneAccessPointId
        accessPointType
        uniqueId
        beaconUUID
        beaconMajor
        beaconMinor
        doorARXId
        classId
        doorName
        doorDescription
        handbackTime
        doorCaption
        priority
        status
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        isDeleted
        batteryLevel
        sodvinDoorId
        sodvinDoorSecret
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getKisiDoorsForSiteLocation = /* GraphQL */ `
  query GetKisiDoorsForSiteLocation($siteLocationId: Int!) {
    getKisiDoorsForSiteLocation(siteLocationId: $siteLocationId) {
      items {
        id
        siteId
        siteLocationId
        kisiDoorId
        kisiDoorName
        kisiPlaceId
        kisiPlaceName
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getKisiEventsForSiteLocation = /* GraphQL */ `
  query GetKisiEventsForSiteLocation($siteLocationId: Int!) {
    getKisiEventsForSiteLocation(siteLocationId: $siteLocationId) {
      items {
        id
        siteId
        siteLocationId
        kisiDoorId
        kisiDoorName
        doorOpenMemberEmail
        doorOpenedTime
        memberId
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        businessName
        memberName
        memberUniqueId
        memberClientId
        memberStatus
        memberAccountBalance
        memberNote
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getMembersForKisiEvent = /* GraphQL */ `
  query GetMembersForKisiEvent($memberEmail: String!) {
    getMembersForKisiEvent(memberEmail: $memberEmail) {
      items {
        id
        uniqueId
        clientId
        memberStatusId
        userId
        username
        name
        address
        phone
        email
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        note
        accountBalance
        accountBalanceChangeDate
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getAllZonesForSiteLocation = /* GraphQL */ `
  query GetAllZonesForSiteLocation($siteLocationId: Int!) {
    getAllZonesForSiteLocation(siteLocationId: $siteLocationId) {
      items {
        id
        siteLocationId
        zoneName
        zoneDescription
        arxReferenceId
        status
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        locationAccessCategoryZoneId
        locationAccessCategoryId
        locationAccessCategoryName
        locationAccessCategoryGender
        locationAccessCategoryArxRefId
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getGymsForSubscriptions = /* GraphQL */ `
  query GetGymsForSubscriptions($subscriptionIds: String, $userId: Int) {
    getGymsForSubscriptions(
      subscriptionIds: $subscriptionIds
      userId: $userId
    ) {
      providerId
      providerName
      gymChainId
      gymChainName
      locationId
      accessPointId
      locationName
      locationCity
      gymInquiriesByUser
      imageUrl
      latitude
      longitude
      beaconUUID
      beaconMajor
      beaconMinor
      membershipName
      defaultLocationDetectionMethod
      doorName
      doorDescription
      status
      doorARXId
      locationAddress
      subscriptionStartDate
      subscriptionEndDate
      subscriptionId
      sodvinDoorId
      sodvinDoorSecret
      sodvinCompanyId
      siteLocationArxReferenceId
      memberUniqueId
      memberArxReferenceId
      siteLocationHandbackTime
      accessPointHandbackTime
      isGranted
      isTrial
      accessPointType
      siteLocationPhone
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      websiteLink
      gymStatus
      forcedAccessStartTime
      forcedAccessEndTime
      forcedAccessIsIndefinite
      overrideLocationDetection
      overrideClientStatuses
      isMemberBlocked
      isSodvinCompany
      customerSiteId
      memberId
      memberPhone
      isDeclined
      isSuspended
      notAvailable
      scheduleType
      programId
      serviceId
      totalVisits
      remainingVisits
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      serviceCategory
      siteLocationIdApi
      subscriptionStatus
      remainingSessions
      isLockerEnabled
      allowDoorOpenOnNoVisit
      locationAccessCategoryId
      locationZoneId
      addVisitAuto
      addArrivalAuto
      arrivalId
      arrivalACId
      arrivalStartDateTime
      arrivalEndDateTime
      siteLocationMembershipId
      yellowboxId
      blockTime
    }
  }
`;
export const getPriorityInfo = /* GraphQL */ `
  query GetPriorityInfo($siteLocationId: Int!) {
    getPriorityInfo(siteLocationId: $siteLocationId) {
      id
      doorARXId
      priority
    }
  }
`;
export const getMembershipsForUser = /* GraphQL */ `
  query GetMembershipsForUser($userId: Int!) {
    getMembershipsForUser(userId: $userId) {
      mlmId
      memberId
      memberName
      gymId
      gymName
      gymChainId
      gymChainName
      subscriptionValidFrom
      subscriptionValidTo
      status
      expiryDate
      profilePicturePath
      membershipName
    }
  }
`;
export const importBeaconData = /* GraphQL */ `
  query ImportBeaconData($siteLocationId: Int) {
    importBeaconData(siteLocationId: $siteLocationId) {
      id
      siteLocationId
      locationZoneId
      locationZoneName
      zoneAccessPointId
      accessPointType
      uniqueId
      beaconUUID
      beaconMajor
      beaconMinor
      doorARXId
      classId
      doorName
      doorDescription
      handbackTime
      doorCaption
      priority
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
      batteryLevel
    }
  }
`;
export const getAccessCard = /* GraphQL */ `
  query GetAccessCard(
    $doorId: String!
    $startTime: String!
    $endTime: String!
    $siteLocationId: Int!
  ) {
    getAccessCard(
      doorId: $doorId
      startTime: $startTime
      endTime: $endTime
      siteLocationId: $siteLocationId
    ) {
      error
      errorType
      errorMsg
      cardId
      cardType
    }
  }
`;
export const arxForceOpen = /* GraphQL */ `
  query ArxForceOpen($doorId: String!, $state: String!) {
    arxForceOpen(doorId: $doorId, state: $state) {
      error
      errorType
      errorMsg
      statusCode
      statusMessage
    }
  }
`;
export const arxForceBlock = /* GraphQL */ `
  query ArxForceBlock($doorId: String!, $state: String!) {
    arxForceBlock(doorId: $doorId, state: $state) {
      error
      errorType
      errorMsg
      statusCode
      statusMessage
    }
  }
`;
export const openArxDoor = /* GraphQL */ `
  query OpenArxDoor($doorId: String!, $personId: String) {
    openArxDoor(doorId: $doorId, personId: $personId) {
      error
      errorType
      errorMsg
      statusCode
      statusMessage
    }
  }
`;
export const openArxDoorDashboard = /* GraphQL */ `
  query OpenArxDoorDashboard(
    $doorId: String!
    $userId: Int!
    $siteLocationId: Int!
    $comment: String!
  ) {
    openArxDoorDashboard(
      doorId: $doorId
      userId: $userId
      siteLocationId: $siteLocationId
      comment: $comment
    ) {
      error
      errorType
      errorMsg
      statusCode
      statusMessage
    }
  }
`;
export const importBeaconIdByUniqueId = /* GraphQL */ `
  query ImportBeaconIdByUniqueId($uniqueId: String) {
    importBeaconIdByUniqueId(uniqueId: $uniqueId) {
      error
      errorMsg
      BeaconId {
        uuid
        major
        minor
      }
    }
  }
`;
export const fetchArxAllAccessCategories = /* GraphQL */ `
  query FetchArxAllAccessCategories($id: String) {
    fetchArxAllAccessCategories(id: $id) {
      items {
        id
        categoryName
      }
      error
      errorType
      errorMsg
    }
  }
`;
export const findARXPersonById = /* GraphQL */ `
  query FindARXPersonById($id: String!) {
    findARXPersonById(id: $id) {
      id
      name
      accessCategories {
        id
        name
        startDate
        endDate
      }
      accessCards {
        cardNumber
        formatName
        cardDescription
      }
      error
      errorType
      errorMsg
    }
  }
`;
export const getActivityForSiteLocation = /* GraphQL */ `
  query GetActivityForSiteLocation(
    $userId: Int!
    $siteLocationId: Int!
    $offset: Int!
    $pageSize: Int!
    $dateFrom: String!
    $dateTo: String!
    $stageId: Int!
    $eventType: String!
    $platformAdmin: Boolean!
  ) {
    getActivityForSiteLocation(
      userId: $userId
      siteLocationId: $siteLocationId
      offset: $offset
      pageSize: $pageSize
      dateFrom: $dateFrom
      dateTo: $dateTo
      stageId: $stageId
      eventType: $eventType
      platformAdmin: $platformAdmin
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getActivityMyTasksForSiteLocation = /* GraphQL */ `
  query GetActivityMyTasksForSiteLocation(
    $userId: Int!
    $roleId: Int!
    $siteLocationId: Int!
    $offset: Int!
    $pageSize: Int!
    $closedInquiries: Boolean!
    $platformAdmin: Boolean!
  ) {
    getActivityMyTasksForSiteLocation(
      userId: $userId
      roleId: $roleId
      siteLocationId: $siteLocationId
      offset: $offset
      pageSize: $pageSize
      closedInquiries: $closedInquiries
      platformAdmin: $platformAdmin
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getActivityVisitsForSiteLocation = /* GraphQL */ `
  query GetActivityVisitsForSiteLocation(
    $userId: Int!
    $siteLocationId: Int!
    $offset: Int!
    $pageSize: Int!
    $platformAdmin: Boolean!
  ) {
    getActivityVisitsForSiteLocation(
      userId: $userId
      siteLocationId: $siteLocationId
      offset: $offset
      pageSize: $pageSize
      platformAdmin: $platformAdmin
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const openSodvinDoor = /* GraphQL */ `
  query OpenSodvinDoor(
    $gymxsGymId: Int!
    $mobileNumber: String!
    $sodvinCustomerId: String!
    $gymxsDoorId: Int!
  ) {
    openSodvinDoor(
      gymxsGymId: $gymxsGymId
      mobileNumber: $mobileNumber
      sodvinCustomerId: $sodvinCustomerId
      gymxsDoorId: $gymxsDoorId
    ) {
      error
      errorType
      errorMsg
      message
      success
    }
  }
`;
export const openSodvinDoorAnonymous = /* GraphQL */ `
  query OpenSodvinDoorAnonymous(
    $name: String!
    $sodvinDoorId: String!
    $sodvinDoorSecret: String!
    $userId: Int!
    $siteLocationId: Int!
    $comment: String!
  ) {
    openSodvinDoorAnonymous(
      name: $name
      sodvinDoorId: $sodvinDoorId
      sodvinDoorSecret: $sodvinDoorSecret
      userId: $userId
      siteLocationId: $siteLocationId
      comment: $comment
    ) {
      error
      errorType
      errorMsg
      message
      success
    }
  }
`;
export const accessPreCheckSodvin = /* GraphQL */ `
  query AccessPreCheckSodvin(
    $sodvinCompanyId: String!
    $gymxsGymId: Int!
    $mobileNumber: String!
    $sodvinCustomerId: String!
    $gymxsDoorId: Int!
  ) {
    accessPreCheckSodvin(
      sodvinCompanyId: $sodvinCompanyId
      gymxsGymId: $gymxsGymId
      mobileNumber: $mobileNumber
      sodvinCustomerId: $sodvinCustomerId
      gymxsDoorId: $gymxsDoorId
    ) {
      error
      errorType
      errorMsg
      message
      accessAllowed
      customerId
      authMessageValue
      authMessageText
    }
  }
`;
export const getMemberEventStatusTypes = /* GraphQL */ `
  query GetMemberEventStatusTypes {
    getMemberEventStatusTypes {
      id
      description
      isDeleted
    }
  }
`;
export const getActivityMyTasksCount = /* GraphQL */ `
  query GetActivityMyTasksCount(
    $userId: Int!
    $roleId: Int!
    $siteLocationId: Int!
    $platformAdmin: Boolean!
  ) {
    getActivityMyTasksCount(
      userId: $userId
      roleId: $roleId
      siteLocationId: $siteLocationId
      platformAdmin: $platformAdmin
    ) {
      count
    }
  }
`;
export const getAllUsers = /* GraphQL */ `
  query GetAllUsers {
    getAllUsers {
      id
      firstName
      lastName
      isPlatformUser
    }
  }
`;
export const getGymsByUniqueId = /* GraphQL */ `
  query GetGymsByUniqueId($uniqueIds: String!) {
    getGymsByUniqueId(uniqueIds: $uniqueIds) {
      gymChainName
      gymChainId
      gymId
      gymIdApi
      gymName
      contactPerson
      phoneNumber
      email
      address
      status
      createdDate
      createdBy
      lastModifiedDate
      lastImportedDate
      lastModifiedBy
      imageUrl
      latitude
      longitude
      trialMembershipId
      staffMembershipId
      freePassId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      isMobileCredentialAllowed
      websiteLink
      city
      membershipOnSignup
      membershipForVisits
      syncAllVisitsWithGenericSubscription
      isDeleted
      signUpUrl
      arxReferenceId
      sodvinCompanyId
      providerId
      allowConvertingAccessCards
      cardType
      addArrivalAuto
      addVisitAuto
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      timeZone
      prioratizeClasses
      blockTime
      markArrivalFirst
      openWithoutArrivalMarked
      markMultipleAttendanceAuto
      markArrivalsWhenThereAreVisits
    }
  }
`;
export const getAvailableEvents = /* GraphQL */ `
  query GetAvailableEvents {
    getAvailableEvents {
      eventId
      eventDescription
      eventTypeId
      eventType
      stageId
      stage
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      username
      firstName
      lastName
      phone
      email
      isActive
      language
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      appVersion
      profilePicturePath
      isProfilePicVerified
      isMobileAppUser
      isAdministrator
      isDeleted
      isSystemUser
      isMobileAppDisabled
      isJustTapRevoked
      defaultLocationDetectionMethod
      isMoreInfoRequested
      memberId
    }
  }
`;
export const importSodvinLocation = /* GraphQL */ `
  query ImportSodvinLocation($sodvinCompanyId: String!) {
    importSodvinLocation(sodvinCompanyId: $sodvinCompanyId) {
      name
      organizationNumber
      webAddress
      logo
      address1
      address2
      zip
      postOffice
      telephone
      email
      error
      errorMsg
    }
  }
`;
export const importSodvinDepartment = /* GraphQL */ `
  query ImportSodvinDepartment(
    $sodvinCompanyId: String!
    $sodvinDepartmentId: String!
  ) {
    importSodvinDepartment(
      sodvinCompanyId: $sodvinCompanyId
      sodvinDepartmentId: $sodvinDepartmentId
    ) {
      name
      organizationNumber
      webAddress
      logo
      address1
      address2
      zip
      postOffice
      telephone
      email
      error
      errorMsg
    }
  }
`;
export const getGymByCompanyId = /* GraphQL */ `
  query GetGymByCompanyId($gymChainId: Int!, $gymCompanyId: String!) {
    getGymByCompanyId(gymChainId: $gymChainId, gymCompanyId: $gymCompanyId) {
      gymChainName
      gymChainId
      gymId
      gymIdApi
      gymName
      contactPerson
      phoneNumber
      email
      address
      status
      createdDate
      createdBy
      lastModifiedDate
      lastImportedDate
      lastModifiedBy
      imageUrl
      latitude
      longitude
      trialMembershipId
      staffMembershipId
      freePassId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      isMobileCredentialAllowed
      websiteLink
      city
      membershipOnSignup
      membershipForVisits
      syncAllVisitsWithGenericSubscription
      isDeleted
      signUpUrl
      arxReferenceId
      sodvinCompanyId
      providerId
      allowConvertingAccessCards
      cardType
      addArrivalAuto
      addVisitAuto
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      timeZone
      prioratizeClasses
      blockTime
      markArrivalFirst
      openWithoutArrivalMarked
      markMultipleAttendanceAuto
      markArrivalsWhenThereAreVisits
    }
  }
`;
export const getAccessCategories = /* GraphQL */ `
  query GetAccessCategories($siteLocationId: Int!) {
    getAccessCategories(siteLocationId: $siteLocationId) {
      totalRecords {
        totalRecords
      }
      items {
        id
        name
        arxAccessCategoryId
        gender
        siteLocationMembershipId
        membershipName
        program
        scheduleType
        status
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        siteLocationId
        schedulesOnly
      }
    }
  }
`;
export const getDaySchedules = /* GraphQL */ `
  query GetDaySchedules(
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $search: SearchInput
  ) {
    getDaySchedules(
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      search: $search
    ) {
      totalRecords {
        totalRecords
      }
      items {
        id
        name
        arxReferenceId
        startTime
        endTime
        status
        weekdayId
        weekdayName
        createdDate
        createdBy
        lastModifiedBy
        lastModifiedDate
      }
    }
  }
`;
export const isAccessCategoryLinked = /* GraphQL */ `
  query IsAccessCategoryLinked($locationAccessCategoryId: Int!) {
    isAccessCategoryLinked(
      locationAccessCategoryId: $locationAccessCategoryId
    ) {
      count
    }
  }
`;
export const getAllWeekDays = /* GraphQL */ `
  query GetAllWeekDays {
    getAllWeekDays {
      id
      name
    }
  }
`;
export const getGymSchedules = /* GraphQL */ `
  query GetGymSchedules($siteId: Int!) {
    getGymSchedules(siteId: $siteId) {
      totalRecords {
        totalRecords
      }
      items {
        siteId
        siteName
        siteScheduleId
        siteScheduleName
        siteScheduleType
        validFrom
        validTo
        scheduleStatusId
        scheduleStatusName
        siteScheduleStatus
        siteScheduleCreatedBy
        siteScheduleCreatedDate
        siteScheduleLastModifiedBy
        siteScheduleLastModifiedDate
        siteScheduleDetailId
        weekdayId
        weekdayName
        startTime
        endTime
        dayScheduleId
        siteScheduleDetailStatus
        siteScheduleDetailCreatedBy
        siteLocationScheduleId
        siteLocationId
        siteLocationName
        imageUrl
        zoneId
        zoneName
        siteLocationAccessCategoryId
        accessCategoryName
        locationAccessPointId
        locationAccessPointName
        siteLocationScheduleCreatedBy
      }
    }
  }
`;
export const importSodvinMemberships = /* GraphQL */ `
  query ImportSodvinMemberships($siteId: Int!) {
    importSodvinMemberships(siteId: $siteId) {
      error
      errorMsg
    }
  }
`;
export const getZoneAccessCategoryMappingForGymSchedules = /* GraphQL */ `
  query GetZoneAccessCategoryMappingForGymSchedules($siteId: Int!) {
    getZoneAccessCategoryMappingForGymSchedules(siteId: $siteId) {
      siteLocationMembershipId
      siteLocationId
      locationName
      locationAccessCategoryId
      locationAccessCategoryName
      locationArxAccessCategoryId
      locationAccessCategoryZoneId
      locationZoneId
      zoneName
      zoneDescription
      siteId
      membershipId
      membershipName
    }
  }
`;
export const getAccessPointsForSite = /* GraphQL */ `
  query GetAccessPointsForSite($siteId: Int!) {
    getAccessPointsForSite(siteId: $siteId) {
      locationAccessPointId
      siteId
      siteLocationId
      locationZoneId
      locationZoneName
      zoneAccessPointId
      doorARXId
      doorName
      status
    }
  }
`;
export const getAllScheduleStatuses = /* GraphQL */ `
  query GetAllScheduleStatuses {
    getAllScheduleStatuses {
      id
      name
    }
  }
`;
export const checkDoorAccess = /* GraphQL */ `
  query CheckDoorAccess(
    $accessPointId: Int!
    $userDateTime: String!
    $memberIds: String!
  ) {
    checkDoorAccess(
      accessPointId: $accessPointId
      userDateTime: $userDateTime
      memberIds: $memberIds
    ) {
      success
    }
  }
`;
export const getPendingIssuesReportedByUser = /* GraphQL */ `
  query GetPendingIssuesReportedByUser($userId: Int!) {
    getPendingIssuesReportedByUser(userId: $userId) {
      issueId
      issueTitle
      issueDescription
      lastModifiedDate
      issueStatus
    }
  }
`;
export const checkDoorExpiry = /* GraphQL */ `
  query CheckDoorExpiry(
    $accessPointId: Int!
    $memberId: Int!
    $userDateTime: String!
  ) {
    checkDoorExpiry(
      accessPointId: $accessPointId
      memberId: $memberId
      userDateTime: $userDateTime
    ) {
      success
      statusMessage
    }
  }
`;
export const getAllRoles = /* GraphQL */ `
  query GetAllRoles {
    getAllRoles {
      roleId
      roleName
      roleDescription
    }
  }
`;
export const getActivityForUser = /* GraphQL */ `
  query GetActivityForUser(
    $userId: Int!
    $offset: Int!
    $pageSize: Int!
    $dateFrom: String!
    $dateTo: String!
    $stageId: Int!
    $eventType: String!
    $platformAdmin: Boolean!
  ) {
    getActivityForUser(
      userId: $userId
      offset: $offset
      pageSize: $pageSize
      dateFrom: $dateFrom
      dateTo: $dateTo
      stageId: $stageId
      eventType: $eventType
      platformAdmin: $platformAdmin
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getActivityMyTasksForUser = /* GraphQL */ `
  query GetActivityMyTasksForUser(
    $userId: Int!
    $roleId: Int!
    $offset: Int!
    $pageSize: Int!
    $closedInquiries: Boolean!
    $platformAdmin: Boolean!
  ) {
    getActivityMyTasksForUser(
      userId: $userId
      roleId: $roleId
      offset: $offset
      pageSize: $pageSize
      closedInquiries: $closedInquiries
      platformAdmin: $platformAdmin
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getActivityMyTasksCountForUser = /* GraphQL */ `
  query GetActivityMyTasksCountForUser(
    $userId: Int!
    $roleId: Int!
    $platformAdmin: Boolean!
  ) {
    getActivityMyTasksCountForUser(
      userId: $userId
      roleId: $roleId
      platformAdmin: $platformAdmin
    ) {
      count
    }
  }
`;
export const getActivityForSite = /* GraphQL */ `
  query GetActivityForSite(
    $userId: Int!
    $siteId: Int!
    $offset: Int!
    $pageSize: Int!
    $dateFrom: String!
    $dateTo: String!
    $stageId: Int!
    $eventType: String!
    $platformAdmin: Boolean!
  ) {
    getActivityForSite(
      userId: $userId
      siteId: $siteId
      offset: $offset
      pageSize: $pageSize
      dateFrom: $dateFrom
      dateTo: $dateTo
      stageId: $stageId
      eventType: $eventType
      platformAdmin: $platformAdmin
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getActivityMyTasksForSite = /* GraphQL */ `
  query GetActivityMyTasksForSite(
    $userId: Int!
    $roleId: Int!
    $siteId: Int!
    $offset: Int!
    $pageSize: Int!
    $closedInquiries: Boolean!
    $platformAdmin: Boolean!
  ) {
    getActivityMyTasksForSite(
      userId: $userId
      roleId: $roleId
      siteId: $siteId
      offset: $offset
      pageSize: $pageSize
      closedInquiries: $closedInquiries
      platformAdmin: $platformAdmin
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getActivityMyTasksCountForSite = /* GraphQL */ `
  query GetActivityMyTasksCountForSite(
    $userId: Int!
    $roleId: Int!
    $siteId: Int!
    $platformAdmin: Boolean!
  ) {
    getActivityMyTasksCountForSite(
      userId: $userId
      roleId: $roleId
      siteId: $siteId
      platformAdmin: $platformAdmin
    ) {
      count
    }
  }
`;
export const getAccessCardHistory = /* GraphQL */ `
  query GetAccessCardHistory($memberId: Int!, $siteLocationId: Int!) {
    getAccessCardHistory(memberId: $memberId, siteLocationId: $siteLocationId) {
      id
      accessCardUID
      userId
      isActive
      deactivatedDateTime
      accessCardFormatName
      memberId
      integrationCardId
      createdDate
      createdBy
      siteLocationId
      lastModifiedDate
      lastModifiedBy
    }
  }
`;
export const getForcedAccess = /* GraphQL */ `
  query GetForcedAccess($memberId: Int!, $siteLocationId: Int!) {
    getForcedAccess(memberId: $memberId, siteLocationId: $siteLocationId) {
      id
      memberLocationId
      startingTime
      endingTime
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isActive
      isIndefinite
      overrideLocationDetection
      overrideClientStatuses
    }
  }
`;
export const getSearchedMembersForSiteLocation = /* GraphQL */ `
  query GetSearchedMembersForSiteLocation(
    $searchTerm: String!
    $siteLocationId: Int!
  ) {
    getSearchedMembersForSiteLocation(
      searchTerm: $searchTerm
      siteLocationId: $siteLocationId
    ) {
      userId
      memberId
      firstName
      lastName
      arxReferenceId
      imageUrl
      hasAccessCard
    }
  }
`;
export const getSiteLocationUser = /* GraphQL */ `
  query GetSiteLocationUser($userId: Int!) {
    getSiteLocationUser(userId: $userId) {
      id
      siteId
      locationId
      userId
    }
  }
`;
export const getMemberLocation = /* GraphQL */ `
  query GetMemberLocation($memberId: Int!) {
    getMemberLocation(memberId: $memberId) {
      id
      siteLocationId
      memberId
      status
      isBlocked
      contractExpiryDate
    }
  }
`;
export const getAccessMethodsForMember = /* GraphQL */ `
  query GetAccessMethodsForMember(
    $userId: Int!
    $memberId: Int!
    $siteLocationId: Int!
  ) {
    getAccessMethodsForMember(
      userId: $userId
      memberId: $memberId
      siteLocationId: $siteLocationId
    ) {
      totalRecords {
        totalRecords
      }
      items {
        accessCardUID
        mobileAppId
        createdDate
        createdBy
        lastModifiedDate
        lastModifiedBy
        isMobileAppDisabled
        isActive
        type
      }
    }
  }
`;
export const getReceivedInformation = /* GraphQL */ `
  query GetReceivedInformation($userId: Int!) {
    getReceivedInformation(userId: $userId) {
      id
      userPhone
      firstName
      lastName
      email
      phone
      address
      birthDate
      inquiryMemberId
      siteId
      siteLocationId
    }
  }
`;
export const getMatchingRecords = /* GraphQL */ `
  query GetMatchingRecords(
    $email: String!
    $phoneNumber: String!
    $birthDate: String!
    $siteId: String!
  ) {
    getMatchingRecords(
      email: $email
      phoneNumber: $phoneNumber
      birthDate: $birthDate
      siteId: $siteId
    ) {
      totalRecords {
        totalRecords
      }
      items {
        id
        firstName
        lastName
        email
        phone
        birthDate
        providerId
        uniqueId
        userId
        address
      }
    }
  }
`;
export const getMembersForUser = /* GraphQL */ `
  query GetMembersForUser($userId: Int!) {
    getMembersForUser(userId: $userId) {
      id
      firstName
      lastName
      phone
      createdDate
      createdBy
      lastModifiedDate
      lastModifiedBy
      email
      lastSyncDate
      isNew
      providerId
      arxReferenceId
      userId
      dateOfBirth
    }
  }
`;
export const getActivityForMember = /* GraphQL */ `
  query GetActivityForMember(
    $userId: Int!
    $offset: Int!
    $pageSize: Int!
    $dateFrom: String!
    $dateTo: String!
    $stageId: Int!
    $eventType: String!
    $platformAdmin: Boolean!
  ) {
    getActivityForMember(
      userId: $userId
      offset: $offset
      pageSize: $pageSize
      dateFrom: $dateFrom
      dateTo: $dateTo
      stageId: $stageId
      eventType: $eventType
      platformAdmin: $platformAdmin
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const filterSchedulesByDate = /* GraphQL */ `
  query FilterSchedulesByDate(
    $subscriptions: String
    $rangeStart: String
    $rangeEnd: String
  ) {
    filterSchedulesByDate(
      subscriptions: $subscriptions
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
    ) {
      date
      schedules {
        name
        startTime
        endTime
      }
    }
  }
`;
export const filterGymOpeningHoursByDate = /* GraphQL */ `
  query FilterGymOpeningHoursByDate(
    $subscriptions: String
    $rangeStart: String
    $rangeEnd: String
  ) {
    filterGymOpeningHoursByDate(
      subscriptions: $subscriptions
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
    ) {
      date
      openingHours {
        name
        startTime
        endTime
      }
    }
  }
`;
export const getDayScheduleLinkCount = /* GraphQL */ `
  query GetDayScheduleLinkCount($dayScheduleId: Int!) {
    getDayScheduleLinkCount(dayScheduleId: $dayScheduleId) {
      count
    }
  }
`;
export const getAccessRules = /* GraphQL */ `
  query GetAccessRules($siteId: Int!) {
    getAccessRules(siteId: $siteId) {
      id
      level
      type
      defaultRef
      serviceResponse
      accessStatus
      maxLimit
      allowAccess
      gracePeriod
      graceAccessCount
      isCustom
      noOfDays
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const getLoggedInUserWeb = /* GraphQL */ `
  query GetLoggedInUserWeb($email: String!) {
    getLoggedInUserWeb(email: $email) {
      userId
      email
      firstName
      lastName
      roleId
      roleName
      language
      siteCount
      locationCount
    }
  }
`;
export const getLastMemberAssignmentHistory = /* GraphQL */ `
  query GetLastMemberAssignmentHistory($userId: Int!, $eventId: Int!) {
    getLastMemberAssignmentHistory(userId: $userId, eventId: $eventId) {
      id
      memberEventId
      createdDate
      assignedBy
      createdBy
      isDeleted
      assignedUserId
      assignedRoleId
      assignedDateTime
      currentlyAssigned
      lastModifiedBy
      lastModifiedDate
      resolvedDescription
      closedDescription
    }
  }
`;
export const getReceivedInformationForMember = /* GraphQL */ `
  query GetReceivedInformationForMember($memberId: Int!) {
    getReceivedInformationForMember(memberId: $memberId) {
      id
      firstName
      lastName
      email
      phone
      imageUrl
    }
  }
`;
export const getEventByDescription = /* GraphQL */ `
  query GetEventByDescription($description: String!) {
    getEventByDescription(description: $description) {
      eventId
      eventDescription
      eventTypeId
      eventType
      stageId
      stage
    }
  }
`;
export const getSodvinBookingForCustomer = /* GraphQL */ `
  query GetSodvinBookingForCustomer(
    $sodvinCompanyId: String!
    $customerId: Int!
    $customerMobile: String!
    $customerBirthdate: String
    $fromDate: String!
    $toDate: String!
    $siteLocationId: Int!
  ) {
    getSodvinBookingForCustomer(
      sodvinCompanyId: $sodvinCompanyId
      customerId: $customerId
      customerMobile: $customerMobile
      customerBirthdate: $customerBirthdate
      fromDate: $fromDate
      toDate: $toDate
      siteLocationId: $siteLocationId
    ) {
      error
      errorType
      errorMsg
      customerBookings {
        groupSessionCustomerId
        customerId
        reservationDate
        cancelationDate
        movedFromWaitingListDate
        groupSessionId
        groupSessionDepartmentId
        groupSessionDepartmentName
        startDate
        endDate
        subject
        reservationStatus
        nrOnWaitingList
        attended
        reservationGUID
        isInCancelInterval
        isInRegistrateAttendencyInterval
        customerFirstName
        customerLastName
        bookingCustomerName
      }
      success
    }
  }
`;
export const getSubscriptionsForSiteLinkedMembers = /* GraphQL */ `
  query GetSubscriptionsForSiteLinkedMembers($userId: Int) {
    getSubscriptionsForSiteLinkedMembers(userId: $userId) {
      subscriptionId
      gymDefLocationDetectionMethod
      gymName
      membershipName
      gymChainName
      gymChainLogo
      userDefLocationDetectionMethod
      memberId
      memberFirstName
      memberLastName
      memberPhone
      userId
      gymLogo
      startDate
      endDate
      isGranted
      isTrial
      isDeleted
      isMemberBlocked
      gymStatus
      gymId
      siteLocationMembershipId
      scheduleType
      programId
      serviceId
      totalVisits
      remainingVisits
      providerId
      subscriptionStatus
      yellowboxId
    }
  }
`;
export const getNewNumberFlow = /* GraphQL */ `
  query GetNewNumberFlow($siteLocationId: Int!, $phone: String!) {
    getNewNumberFlow(siteLocationId: $siteLocationId, phone: $phone) {
      error
      errorType
      errorMsg
      memberIds
      success
    }
  }
`;
export const getActivityForSiteLocationMember = /* GraphQL */ `
  query GetActivityForSiteLocationMember(
    $userId: Int!
    $siteLocationId: Int!
    $offset: Int!
    $pageSize: Int!
    $dateFrom: String!
    $dateTo: String!
    $stageId: Int!
    $eventType: String!
    $platformAdmin: Boolean!
    $memberId: Int!
  ) {
    getActivityForSiteLocationMember(
      userId: $userId
      siteLocationId: $siteLocationId
      offset: $offset
      pageSize: $pageSize
      dateFrom: $dateFrom
      dateTo: $dateTo
      stageId: $stageId
      eventType: $eventType
      platformAdmin: $platformAdmin
      memberId: $memberId
    ) {
      items {
        memberEventId
        memberId
        memberEmail
        eventId
        accessCardUID
        eventDescription
        eventType
        stageId
        stage
        createdTime
        isAutoAssigned
        memberEventStatusTypeId
        memberEventStatusType
        firstName
        lastName
        siteLocationId
        assignedUserId
        assignmentHistoryId
        locationName
        lastModifiedBy
        lastModifiedDate
        inquiryDescription
        memberLocationId
        informationReceivedEvent
        language
        userId
        en
        nor
        nl
      }
      totalRecords {
        totalRecords
      }
    }
  }
`;
export const getProviderForGym = /* GraphQL */ `
  query GetProviderForGym($siteLocationId: Int!) {
    getProviderForGym(siteLocationId: $siteLocationId) {
      siteLocationId
      providerId
      providerName
    }
  }
`;
export const getAllAccessCardTypes = /* GraphQL */ `
  query GetAllAccessCardTypes {
    getAllAccessCardTypes {
      id
      accessCard
      value
    }
  }
`;
export const getScheduleRequests = /* GraphQL */ `
  query GetScheduleRequests {
    getScheduleRequests {
      totalRecords {
        totalRecords
      }
      items {
        scheduleId
        gymName
        gymChainName
        scheduleName
        status
        createdDate
        weekdayId
        startTime
        endTime
        gymChainId
        weekdayName
        gymId
      }
    }
  }
`;
export const getAllGymChains = /* GraphQL */ `
  query GetAllGymChains {
    getAllGymChains {
      gymChainId
      gymChainName
    }
  }
`;
export const validateAccess = /* GraphQL */ `
  query ValidateAccess(
    $accessPointId: Int!
    $userId: Int!
    $userTime: String!
    $lowerBound: Int
    $upperBound: Int
  ) {
    validateAccess(
      accessPointId: $accessPointId
      userId: $userId
      userTime: $userTime
      lowerBound: $lowerBound
      upperBound: $upperBound
    ) {
      accessStatus
      accessGranted
      isGraceAccess
      gracePeriod
      remainingGraceAccessCount
      accessRuleId
      isGranted
      isTrial
      activeSubscriptionIds
      validatedSubscriptionId
      subscriptionData {
        subscriptionId
        programId
        scheduleType
      }
    }
  }
`;
export const validateForcedAccessOverride = /* GraphQL */ `
  query ValidateForcedAccessOverride(
    $accessPointId: Int!
    $userId: Int!
    $userTime: String!
  ) {
    validateForcedAccessOverride(
      accessPointId: $accessPointId
      userId: $userId
      userTime: $userTime
    ) {
      accessStatus
      accessGranted
      isGraceAccess
      gracePeriod
      remainingGraceAccessCount
      accessRuleId
      isGranted
      isTrial
      activeSubscriptionIds
      validatedSubscriptionId
      subscriptionData {
        subscriptionId
        programId
        scheduleType
      }
    }
  }
`;
export const getMindbodyAppointments = /* GraphQL */ `
  query GetMindbodyAppointments(
    $subscriptionIds: String!
    $rangeStart: String
    $rangeEnd: String
  ) {
    getMindbodyAppointments(
      subscriptionIds: $subscriptionIds
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
    ) {
      id
      memberLocationMembershipId
      importedId
      name
      startDateTime
      endDateTime
      isVisited
      isDeleted
      serviceCategory
      remainingVisits
      siteId
      customerSiteId
      locationId
      insideBlockTime
    }
  }
`;
export const getYellowboxBookingsOrAvailability = /* GraphQL */ `
  query GetYellowboxBookingsOrAvailability(
    $memberId: Int!
    $locationId: String!
  ) {
    getYellowboxBookingsOrAvailability(
      memberId: $memberId
      locationId: $locationId
    ) {
      availability {
        prefix
        prefixLong
      }
      bookings {
        lockerId
        location
        lockerNumber
        prefix
        prefixLong
        bookingStart
      }
    }
  }
`;
export const getSiteScheduleDetailsById = /* GraphQL */ `
  query GetSiteScheduleDetailsById($id: Int!) {
    getSiteScheduleDetailsById(id: $id) {
      id
      siteScheduleId
      isRequested
      siteId
    }
  }
`;
export const getActiveAccessCardForUser = /* GraphQL */ `
  query GetActiveAccessCardForUser($userId: Int!) {
    getActiveAccessCardForUser(userId: $userId) {
      accessCardUID
      accessCardFormatName
      memberArxReferenceId
    }
  }
`;
export const importPrograms = /* GraphQL */ `
  query ImportPrograms($siteId: Int!, $customerSiteId: Int!) {
    importPrograms(siteId: $siteId, customerSiteId: $customerSiteId) {
      error
      errorType
      errorMsg
      rollbackStatus
    }
  }
`;
export const getAllPrograms = /* GraphQL */ `
  query GetAllPrograms($siteId: Int!) {
    getAllPrograms(siteId: $siteId) {
      id
      siteId
      importedId
      programName
      scheduleType
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      linkedProgramId
      linkCreatedBy
      linkCreatedDate
      linkLastModifiedBy
      linkLastModifiedDate
    }
  }
`;
export const getGymClusters = /* GraphQL */ `
  query GetGymClusters($memberIds: String) {
    getGymClusters(memberIds: $memberIds) {
      providerId
      providerName
      gymChainId
      gymChainName
      locationId
      accessPointId
      locationName
      locationCity
      gymInquiriesByUser
      imageUrl
      latitude
      longitude
      beaconUUID
      beaconMajor
      beaconMinor
      membershipName
      defaultLocationDetectionMethod
      doorName
      doorDescription
      status
      doorARXId
      locationAddress
      subscriptionStartDate
      subscriptionEndDate
      subscriptionId
      sodvinDoorId
      sodvinDoorSecret
      sodvinCompanyId
      siteLocationArxReferenceId
      memberUniqueId
      memberArxReferenceId
      siteLocationHandbackTime
      accessPointHandbackTime
      isGranted
      isTrial
      accessPointType
      siteLocationPhone
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      websiteLink
      gymStatus
      forcedAccessStartTime
      forcedAccessEndTime
      forcedAccessIsIndefinite
      overrideLocationDetection
      overrideClientStatuses
      isMemberBlocked
      isSodvinCompany
      customerSiteId
      memberId
      memberPhone
      isDeclined
      isSuspended
      notAvailable
      scheduleType
      programId
      serviceId
      totalVisits
      remainingVisits
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      serviceCategory
      siteLocationIdApi
      subscriptionStatus
      remainingSessions
      isLockerEnabled
      allowDoorOpenOnNoVisit
      locationAccessCategoryId
      locationZoneId
      addVisitAuto
      addArrivalAuto
      arrivalId
      arrivalACId
      arrivalStartDateTime
      arrivalEndDateTime
      siteLocationMembershipId
      yellowboxId
      blockTime
    }
  }
`;
export const getMemberSyncStatus = /* GraphQL */ `
  query GetMemberSyncStatus($userId: String, $memberIds: String) {
    getMemberSyncStatus(userId: $userId, memberIds: $memberIds) {
      id
    }
  }
`;
export const validateArrivals = /* GraphQL */ `
  query ValidateArrivals(
    $subscriptionData: String!
    $userTime: String!
    $prioratizedSubscription: String!
  ) {
    validateArrivals(
      subscriptionData: $subscriptionData
      userTime: $userTime
      prioratizedSubscription: $prioratizedSubscription
    ) {
      marked
      unmarked
    }
  }
`;
export const validateVisits = /* GraphQL */ `
  query ValidateVisits(
    $subscriptionData: String!
    $userTime: String!
    $blockTime: Int
    $lowerBound: Int
    $upperBound: Int
  ) {
    validateVisits(
      subscriptionData: $subscriptionData
      userTime: $userTime
      blockTime: $blockTime
      lowerBound: $lowerBound
      upperBound: $upperBound
    ) {
      id
      memberLocationMembershipId
      importedId
      name
      startDateTime
      endDateTime
      isVisited
      isDeleted
      serviceCategory
      remainingVisits
      siteId
      customerSiteId
      locationId
      insideBlockTime
    }
  }
`;
export const pullBookings = /* GraphQL */ `
  query PullBookings(
    $gymId: Int!
    $memberId: Int!
    $filterStart: String!
    $filterEnd: String!
    $userTime: String!
    $blockTime: Int
    $lowerBound: Int
    $upperBound: Int
  ) {
    pullBookings(
      gymId: $gymId
      memberId: $memberId
      filterStart: $filterStart
      filterEnd: $filterEnd
      userTime: $userTime
      blockTime: $blockTime
      lowerBound: $lowerBound
      upperBound: $upperBound
    ) {
      id
      memberLocationMembershipId
      importedId
      name
      startDateTime
      endDateTime
      isVisited
      isDeleted
      serviceCategory
      remainingVisits
      siteId
      customerSiteId
      locationId
      insideBlockTime
    }
  }
`;
export const getMembersByPhoneNumber = /* GraphQL */ `
  query GetMembersByPhoneNumber($phone: String!, $dialingPhone: String!) {
    getMembersByPhoneNumber(phone: $phone, dialingPhone: $dialingPhone) {
      id
      userId
      firstName
      lastName
      email
    }
  }
`;
export const getNewMembersForUser = /* GraphQL */ `
  query GetNewMembersForUser($phone: String!) {
    getNewMembersForUser(phone: $phone) {
      id
      firstName
      lastName
      phone
      email
      arxReferenceId
      userId
    }
  }
`;
