/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addItem = /* GraphQL */ `
  mutation AddItem($input: ItemInput!) {
    addItem(input: $input) {
      id
      type
      price
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem($input: String!) {
    deleteItem(input: $input) {
      id
      type
      price
    }
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser($input: UserInput!) {
    addUser(input: $input) {
      id
      username
      firstName
      lastName
      phone
      email
      isActive
      language
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      appVersion
      profilePicturePath
      isProfilePicVerified
      isMobileAppUser
      isAdministrator
      isDeleted
      isSystemUser
      isMobileAppDisabled
      isJustTapRevoked
      defaultLocationDetectionMethod
      isMoreInfoRequested
      memberId
    }
  }
`;
export const addDeviceHistory = /* GraphQL */ `
  mutation AddDeviceHistory($input: DeviceHistoryInput!) {
    addDeviceHistory(input: $input) {
      id
      userId
      appVersion
      macAddress
      uniqueId
      model
      manufacturer
      isActive
      isCameraPresent
      locale
      type
      timeZone
      signedUpStatus
      country
      baseOs
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const setActiveDevice = /* GraphQL */ `
  mutation SetActiveDevice($id: Int!) {
    setActiveDevice(id: $id) {
      id
      userId
      appVersion
      macAddress
      uniqueId
      model
      manufacturer
      isActive
      isCameraPresent
      locale
      type
      timeZone
      signedUpStatus
      country
      baseOs
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const deleteGymChain = /* GraphQL */ `
  mutation DeleteGymChain($id: Int!) {
    deleteGymChain(id: $id) {
      id
      providerId
      customerSiteId
      customerName
      customerContactPerson
      customerPhone
      supportPhone
      maximumNegativeBalance
      customerEmail
      customerAddress
      status
      membershipsImported
      createdBy
      lastModifiedBy
      createdDate
      lastModifiedDate
      lastImportedDate
      imageUrl
      isDeleted
      arxReferenceId
      providerConnected
      isSodvinCompany
      gymCount
      allowConvertingAccessCards
      processBookings
      processSales
      processAppointments
      fetchAccountBalance
      kisiApiKey
      kisiNightlySyncApiKey
    }
  }
`;
export const addGymChains = /* GraphQL */ `
  mutation AddGymChains($input: WebGymChainInput!) {
    addGymChains(input: $input) {
      id
      providerId
      customerSiteId
      customerName
      customerContactPerson
      customerPhone
      supportPhone
      maximumNegativeBalance
      customerEmail
      customerAddress
      status
      membershipsImported
      createdBy
      lastModifiedBy
      createdDate
      lastModifiedDate
      lastImportedDate
      imageUrl
      isDeleted
      arxReferenceId
      providerConnected
      isSodvinCompany
      gymCount
      allowConvertingAccessCards
      processBookings
      processSales
      processAppointments
      fetchAccountBalance
      kisiApiKey
      kisiNightlySyncApiKey
    }
  }
`;
export const modifyGymChain = /* GraphQL */ `
  mutation ModifyGymChain($input: WebGymChainInput!) {
    modifyGymChain(input: $input) {
      id
      providerId
      customerSiteId
      customerName
      customerContactPerson
      customerPhone
      supportPhone
      maximumNegativeBalance
      customerEmail
      customerAddress
      status
      membershipsImported
      createdBy
      lastModifiedBy
      createdDate
      lastModifiedDate
      lastImportedDate
      imageUrl
      isDeleted
      arxReferenceId
      providerConnected
      isSodvinCompany
      gymCount
      allowConvertingAccessCards
      processBookings
      processSales
      processAppointments
      fetchAccountBalance
      kisiApiKey
      kisiNightlySyncApiKey
    }
  }
`;
export const deleteGym = /* GraphQL */ `
  mutation DeleteGym($id: Int!) {
    deleteGym(id: $id) {
      id
      siteId
      siteLocationIdApi
      locationName
      locationContactPerson
      locationPhone
      locationEmail
      locationAddress
      latitude
      longitude
      freePassId
      trailMembershipId
      staffAccessMembershipId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
      isLockerEnabled
    }
  }
`;
export const deleteKisiDoor = /* GraphQL */ `
  mutation DeleteKisiDoor($id: Int!, $lastModifiedBy: Int!) {
    deleteKisiDoor(id: $id, lastModifiedBy: $lastModifiedBy) {
      id
      siteId
      siteLocationId
      kisiDoorId
      kisiDoorName
      kisiPlaceId
      kisiPlaceName
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const addMembership = /* GraphQL */ `
  mutation AddMembership($input: MembershipInput!) {
    addMembership(input: $input) {
      id
      siteId
      importedId
      membershipName
      membershipTypeId
      isImported
      validityPeriod
      isMinusCreditBalanceApplied
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const modifyMembership = /* GraphQL */ `
  mutation ModifyMembership($input: MembershipInput!) {
    modifyMembership(input: $input) {
      id
      siteId
      importedId
      membershipName
      membershipTypeId
      isImported
      validityPeriod
      isMinusCreditBalanceApplied
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const addGym = /* GraphQL */ `
  mutation AddGym($input: GymInput!) {
    addGym(input: $input) {
      gymChainName
      gymChainId
      gymId
      gymIdApi
      gymName
      contactPerson
      phoneNumber
      email
      address
      status
      createdDate
      createdBy
      lastModifiedDate
      lastImportedDate
      lastModifiedBy
      imageUrl
      latitude
      longitude
      trialMembershipId
      staffMembershipId
      freePassId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      isMobileCredentialAllowed
      websiteLink
      city
      membershipOnSignup
      membershipForVisits
      syncAllVisitsWithGenericSubscription
      isDeleted
      signUpUrl
      arxReferenceId
      sodvinCompanyId
      providerId
      allowConvertingAccessCards
      cardType
      addArrivalAuto
      addVisitAuto
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      timeZone
      prioratizeClasses
      blockTime
      markArrivalFirst
      openWithoutArrivalMarked
      markMultipleAttendanceAuto
      markArrivalsWhenThereAreVisits
    }
  }
`;
export const addKisiDoor = /* GraphQL */ `
  mutation AddKisiDoor($input: KisiDoorInput!) {
    addKisiDoor(input: $input) {
      id
      siteId
      siteLocationId
      kisiDoorId
      kisiDoorName
      kisiPlaceId
      kisiPlaceName
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const setProfilePicture = /* GraphQL */ `
  mutation SetProfilePicture($input: UserInput!) {
    setProfilePicture(input: $input) {
      id
      username
      firstName
      lastName
      phone
      email
      isActive
      language
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      appVersion
      profilePicturePath
      isProfilePicVerified
      isMobileAppUser
      isAdministrator
      isDeleted
      isSystemUser
      isMobileAppDisabled
      isJustTapRevoked
      defaultLocationDetectionMethod
      isMoreInfoRequested
      memberId
    }
  }
`;
export const addSiteLocationMembership = /* GraphQL */ `
  mutation AddSiteLocationMembership($input: SiteLocationMembershipInput) {
    addSiteLocationMembership(input: $input) {
      id
      siteLocationId
      membershipId
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const removeSiteLocationMembership = /* GraphQL */ `
  mutation RemoveSiteLocationMembership($id: Int) {
    removeSiteLocationMembership(id: $id) {
      id
      siteLocationId
      membershipId
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const selectSubscriptions = /* GraphQL */ `
  mutation SelectSubscriptions($input: SubscriptionSelection) {
    selectSubscriptions(input: $input) {
      id
      isSelected
    }
  }
`;
export const rejectSubscriptions = /* GraphQL */ `
  mutation RejectSubscriptions($input: SubscriptionRejection) {
    rejectSubscriptions(input: $input) {
      id
      isRejected
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership($id: Int!) {
    deleteMembership(id: $id) {
      id
      siteId
      importedId
      membershipName
      membershipTypeId
      isImported
      validityPeriod
      isMinusCreditBalanceApplied
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const modifyGym = /* GraphQL */ `
  mutation ModifyGym($input: GymInput!) {
    modifyGym(input: $input) {
      gymChainName
      gymChainId
      gymId
      gymIdApi
      gymName
      contactPerson
      phoneNumber
      email
      address
      status
      createdDate
      createdBy
      lastModifiedDate
      lastImportedDate
      lastModifiedBy
      imageUrl
      latitude
      longitude
      trialMembershipId
      staffMembershipId
      freePassId
      handbackTime
      defaultLocationDetectionMethod
      primaryAccessMethod
      externalBookingsLink
      facebookLink
      twitterLink
      instagramLink
      gracePeriodWhenDeclined
      accessPerDayWhenDeclined
      gracePeriodWhenExpired
      accessPerDayWhenExpired
      gracePeriodWhenSuspended
      accessPerDayWhenSuspended
      isMobileCredentialAllowed
      websiteLink
      city
      membershipOnSignup
      membershipForVisits
      syncAllVisitsWithGenericSubscription
      isDeleted
      signUpUrl
      arxReferenceId
      sodvinCompanyId
      providerId
      allowConvertingAccessCards
      cardType
      addArrivalAuto
      addVisitAuto
      sessionCheckTimeLimit
      sessionCheckTimeLimitUpper
      timeZone
      prioratizeClasses
      blockTime
      markArrivalFirst
      openWithoutArrivalMarked
      markMultipleAttendanceAuto
      markArrivalsWhenThereAreVisits
    }
  }
`;
export const modifyKisiDoor = /* GraphQL */ `
  mutation ModifyKisiDoor($input: KisiDoorInput!) {
    modifyKisiDoor(input: $input) {
      id
      siteId
      siteLocationId
      kisiDoorId
      kisiDoorName
      kisiPlaceId
      kisiPlaceName
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const addLocationOpeningHour = /* GraphQL */ `
  mutation AddLocationOpeningHour($input: LocationOpeningHourInput!) {
    addLocationOpeningHour(input: $input) {
      id
      locationId
      name
      isHoliday
      isGeneral
      validFromDateTime
      validToDateTime
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      status
      isDeleted
    }
  }
`;
export const addLocationOpeningHourDetail = /* GraphQL */ `
  mutation AddLocationOpeningHourDetail(
    $input: LocationOpeningHourDetailInput!
  ) {
    addLocationOpeningHourDetail(input: $input)
  }
`;
export const updateImportMembershipSite = /* GraphQL */ `
  mutation UpdateImportMembershipSite($status: Boolean!, $siteId: Int!) {
    updateImportMembershipSite(status: $status, siteId: $siteId) {
      id
      providerId
      customerSiteId
      customerName
      customerContactPerson
      customerPhone
      customerEmail
      customerAddress
      membershipsImported
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const modifyLocationOpeningHour = /* GraphQL */ `
  mutation ModifyLocationOpeningHour($input: LocationOpeningHourInput!) {
    modifyLocationOpeningHour(input: $input) {
      id
      locationId
      name
      isHoliday
      isGeneral
      validFromDateTime
      validToDateTime
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      status
      isDeleted
    }
  }
`;
export const removeLocationOpeningHourDetailByDay = /* GraphQL */ `
  mutation RemoveLocationOpeningHourDetailByDay(
    $openingHourId: Int!
    $dayOfWeek: String!
  ) {
    removeLocationOpeningHourDetailByDay(
      openingHourId: $openingHourId
      dayOfWeek: $dayOfWeek
    ) {
      id
      openingHourId
      startDateTime
      endDateTime
      dayOfWeek
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const removeLocationOpeningHourDetailByTime = /* GraphQL */ `
  mutation RemoveLocationOpeningHourDetailByTime(
    $openingHourId: Int!
    $startDateTime: String!
    $endDateTime: String!
  ) {
    removeLocationOpeningHourDetailByTime(
      openingHourId: $openingHourId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
    ) {
      id
      openingHourId
      startDateTime
      endDateTime
      dayOfWeek
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const addLocationMembershipOpeningHour = /* GraphQL */ `
  mutation AddLocationMembershipOpeningHour(
    $input: LocationMembershipOpeningHourInput!
  ) {
    addLocationMembershipOpeningHour(input: $input)
  }
`;
export const deleteLocationOpeningHours = /* GraphQL */ `
  mutation DeleteLocationOpeningHours($id: Int!) {
    deleteLocationOpeningHours(id: $id) {
      id
      locationId
      name
      isHoliday
      isGeneral
      validFromDateTime
      validToDateTime
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      status
      isDeleted
    }
  }
`;
export const removeLocationMembershipOpeningHour = /* GraphQL */ `
  mutation RemoveLocationMembershipOpeningHour($id: Int!) {
    removeLocationMembershipOpeningHour(id: $id) {
      id
      openingHourId
      membershipId
      name
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
    }
  }
`;
export const changeMemberLocationIsLocked = /* GraphQL */ `
  mutation ChangeMemberLocationIsLocked(
    $memberId: Int!
    $gymId: Int!
    $isLocked: Boolean!
  ) {
    changeMemberLocationIsLocked(
      memberId: $memberId
      gymId: $gymId
      isLocked: $isLocked
    ) {
      memberId
      gymId
      isLocked
    }
  }
`;
export const changePhoneNumber = /* GraphQL */ `
  mutation ChangePhoneNumber(
    $siteId: Int!
    $customerSiteId: Int!
    $uniqueId: Int!
    $phoneNumber: String!
  ) {
    changePhoneNumber(
      siteId: $siteId
      customerSiteId: $customerSiteId
      uniqueId: $uniqueId
      phoneNumber: $phoneNumber
    ) {
      error
      errorType
      errorMsg
    }
  }
`;
export const changeUserLocationDetectionMethod = /* GraphQL */ `
  mutation ChangeUserLocationDetectionMethod(
    $memberId: Int!
    $method: String!
  ) {
    changeUserLocationDetectionMethod(memberId: $memberId, method: $method) {
      memberId
      method
    }
  }
`;
export const addInquiry = /* GraphQL */ `
  mutation AddInquiry($input: MemberInquiryInput!) {
    addInquiry(input: $input) {
      id
      userId
      phoneNumber
      email
      dateOfBirth
      address
      title
      messageDescription
    }
  }
`;
export const addLocationZone = /* GraphQL */ `
  mutation AddLocationZone(
    $input: LocationZoneInput!
    $accessCategories: [Int]
  ) {
    addLocationZone(input: $input, accessCategories: $accessCategories) {
      error
      errorType
      errorMsg
      rollbackStatus
    }
  }
`;
export const addLocationMembershipZone = /* GraphQL */ `
  mutation AddLocationMembershipZone($input: LocationZoneMembershipInput!) {
    addLocationMembershipZone(input: $input)
  }
`;
export const modifyLocationZone = /* GraphQL */ `
  mutation ModifyLocationZone($input: LocationZoneInput!) {
    modifyLocationZone(input: $input) {
      id
      siteLocationId
      zoneName
      zoneDescription
      arxReferenceId
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      locationAccessCategoryZoneId
      locationAccessCategoryId
      locationAccessCategoryName
      locationAccessCategoryGender
      locationAccessCategoryArxRefId
    }
  }
`;
export const removeLocationMembershipZone = /* GraphQL */ `
  mutation RemoveLocationMembershipZone(
    $locationZoneId: Int!
    $membershipId: Int!
  ) {
    removeLocationMembershipZone(
      locationZoneId: $locationZoneId
      membershipId: $membershipId
    ) {
      id
      locationZoneId
      membershipId
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const removeZoneForSiteLocation = /* GraphQL */ `
  mutation RemoveZoneForSiteLocation($id: Int!, $userId: Int!) {
    removeZoneForSiteLocation(id: $id, userId: $userId)
  }
`;
export const modifyMember = /* GraphQL */ `
  mutation ModifyMember($member: MemberInput!) {
    modifyMember(member: $member) {
      memberId
      memberFirstName
      memberLastName
      isMobileAppDisabled
      isJustTapRevoked
      isStaffMember
      validFrom
      validTo
      signedUpDate
      phoneNumber
      status
      createdDate
      createdBy
      lastModifiedDate
      lastModifiedBy
      expiredDate
      isBlocked
      membership
      defaultGymDetectionMethod
      email
      profilePicturePath
      membershipStatus
      forcedAccessStartTime
      forcedAccessEndTime
      lastSyncDate
      subscriptionDeleted
      isNew
      isTrial
      arxReferenceId
      userId
    }
  }
`;
export const addMemberLocationMembership = /* GraphQL */ `
  mutation AddMemberLocationMembership($input: MemberLocationMembershipInput) {
    addMemberLocationMembership(input: $input) {
      id
      siteLocationMembershipId
      memberId
      status
      subscriptionStartDate
      subscriptionEndDate
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isSelected
      isNotMyGym
      isRejected
      isDeleted
      isGranted
    }
  }
`;
export const removeMemberLocationMembership = /* GraphQL */ `
  mutation RemoveMemberLocationMembership(
    $memberId: Int!
    $siteLocationMembershipId: Int!
    $userId: Int!
  ) {
    removeMemberLocationMembership(
      memberId: $memberId
      siteLocationMembershipId: $siteLocationMembershipId
      userId: $userId
    ) {
      id
      siteLocationMembershipId
      memberId
      status
      subscriptionStartDate
      subscriptionEndDate
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isSelected
      isNotMyGym
      isRejected
      isDeleted
      isGranted
    }
  }
`;
export const addLocationAccessPointDoor = /* GraphQL */ `
  mutation AddLocationAccessPointDoor($input: LocationAccessPointDoorInput!) {
    addLocationAccessPointDoor(input: $input) {
      id
      siteLocationId
      locationZoneId
      locationZoneName
      zoneAccessPointId
      accessPointType
      uniqueId
      beaconUUID
      beaconMajor
      beaconMinor
      doorARXId
      classId
      doorName
      doorDescription
      handbackTime
      doorCaption
      priority
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
      batteryLevel
      sodvinDoorId
      sodvinDoorSecret
    }
  }
`;
export const addLocationAccessPointClass = /* GraphQL */ `
  mutation AddLocationAccessPointClass($input: LocationAccessPointClassInput!) {
    addLocationAccessPointClass(input: $input) {
      id
      siteLocationId
      locationZoneId
      locationZoneName
      zoneAccessPointId
      accessPointType
      uniqueId
      beaconUUID
      beaconMajor
      beaconMinor
      doorARXId
      classId
      doorName
      doorDescription
      handbackTime
      doorCaption
      priority
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
      batteryLevel
      sodvinDoorId
      sodvinDoorSecret
    }
  }
`;
export const modifyLocationAccessPointDoor = /* GraphQL */ `
  mutation ModifyLocationAccessPointDoor(
    $input: LocationAccessPointDoorInput!
  ) {
    modifyLocationAccessPointDoor(input: $input) {
      id
      siteLocationId
      locationZoneId
      locationZoneName
      zoneAccessPointId
      accessPointType
      uniqueId
      beaconUUID
      beaconMajor
      beaconMinor
      doorARXId
      classId
      doorName
      doorDescription
      handbackTime
      doorCaption
      priority
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
      batteryLevel
      sodvinDoorId
      sodvinDoorSecret
    }
  }
`;
export const modifyLocationAccessPointClass = /* GraphQL */ `
  mutation ModifyLocationAccessPointClass(
    $input: LocationAccessPointClassInput!
  ) {
    modifyLocationAccessPointClass(input: $input) {
      id
      siteLocationId
      locationZoneId
      locationZoneName
      zoneAccessPointId
      accessPointType
      uniqueId
      beaconUUID
      beaconMajor
      beaconMinor
      doorARXId
      classId
      doorName
      doorDescription
      handbackTime
      doorCaption
      priority
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isDeleted
      batteryLevel
      sodvinDoorId
      sodvinDoorSecret
    }
  }
`;
export const modifyZoneAccessPoint = /* GraphQL */ `
  mutation ModifyZoneAccessPoint($input: ZoneAccessPointInput!) {
    modifyZoneAccessPoint(input: $input)
  }
`;
export const addZoneAccessPoint = /* GraphQL */ `
  mutation AddZoneAccessPoint($input: ZoneAccessPointInput!) {
    addZoneAccessPoint(input: $input)
  }
`;
export const removeLocationAccessPoint = /* GraphQL */ `
  mutation RemoveLocationAccessPoint($id: Int!, $lastModifiedBy: Int!) {
    removeLocationAccessPoint(id: $id, lastModifiedBy: $lastModifiedBy)
  }
`;
export const addMemberMobileNumberHistory = /* GraphQL */ `
  mutation AddMemberMobileNumberHistory($input: MobileNumberHistoryInput) {
    addMemberMobileNumberHistory(input: $input)
  }
`;
export const resyncMemberData = /* GraphQL */ `
  mutation ResyncMemberData($params: ResyncMemberArgs) {
    resyncMemberData(params: $params) {
      error
      message
      memberModified
      membershipsModified
    }
  }
`;
export const mindbodyNightlySync = /* GraphQL */ `
  mutation MindbodyNightlySync($siteId: Int!, $syncInProgress: Boolean!) {
    mindbodyNightlySync(siteId: $siteId, syncInProgress: $syncInProgress) {
      error
      message
    }
  }
`;
export const mindbodyNightlySyncStatus = /* GraphQL */ `
  mutation MindbodyNightlySyncStatus($siteId: Int!) {
    mindbodyNightlySyncStatus(siteId: $siteId) {
      error
      message
      nightlySyncRunning
    }
  }
`;
export const updateLocationAccessPointBatteryLevel = /* GraphQL */ `
  mutation UpdateLocationAccessPointBatteryLevel(
    $id: Int!
    $batteryLevel: Int!
  ) {
    updateLocationAccessPointBatteryLevel(id: $id, batteryLevel: $batteryLevel)
  }
`;
export const addUserInformationRequest = /* GraphQL */ `
  mutation AddUserInformationRequest($userId: Int!, $createdBy: Int!) {
    addUserInformationRequest(userId: $userId, createdBy: $createdBy)
  }
`;
export const sendInvitationEmail = /* GraphQL */ `
  mutation SendInvitationEmail($input: InvitationEmailInput!) {
    sendInvitationEmail(input: $input) {
      error
      errorMessage
    }
  }
`;
export const arxImportPerson = /* GraphQL */ `
  mutation ArxImportPerson($input: ArxPersonInput!) {
    arxImportPerson(input: $input) {
      error
      errorType
      errorMsg
      statusCode
      statusMessage
    }
  }
`;
export const updateAccessCardOfPerson = /* GraphQL */ `
  mutation UpdateAccessCardOfPerson($input: ArxCarfOfPersonInput!) {
    updateAccessCardOfPerson(input: $input) {
      error
      errorType
      errorMsg
      statusCode
      statusMessage
    }
  }
`;
export const inactivateBeacons = /* GraphQL */ `
  mutation InactivateBeacons($input: InactivateBeaconsInput!) {
    inactivateBeacons(input: $input)
  }
`;
export const resolveMemberEvent = /* GraphQL */ `
  mutation ResolveMemberEvent(
    $resolvedDescription: String!
    $memberEventId: Int!
    $memberAssignmentId: Int!
    $memberEventStatusId: Int!
    $assignedRoleId: Int!
    $userId: Int!
  ) {
    resolveMemberEvent(
      resolvedDescription: $resolvedDescription
      memberEventId: $memberEventId
      memberAssignmentId: $memberAssignmentId
      memberEventStatusId: $memberEventStatusId
      assignedRoleId: $assignedRoleId
      userId: $userId
    )
  }
`;
export const closeMemberEvent = /* GraphQL */ `
  mutation CloseMemberEvent(
    $memberEventId: Int!
    $memberAssignmentId: Int!
    $memberEventStatusId: Int!
    $closedDescription: String!
    $lastModifiedBy: Int!
  ) {
    closeMemberEvent(
      memberEventId: $memberEventId
      memberAssignmentId: $memberAssignmentId
      memberEventStatusId: $memberEventStatusId
      closedDescription: $closedDescription
      lastModifiedBy: $lastModifiedBy
    )
  }
`;
export const assignMemberEvent = /* GraphQL */ `
  mutation AssignMemberEvent($input: AssignMemberEventInput!) {
    assignMemberEvent(input: $input)
  }
`;
export const updateMemberEventStatus = /* GraphQL */ `
  mutation UpdateMemberEventStatus(
    $memberEventId: Int!
    $memberEventStatusId: Int!
  ) {
    updateMemberEventStatus(
      memberEventId: $memberEventId
      memberEventStatusId: $memberEventStatusId
    )
  }
`;
export const addMemberEvent = /* GraphQL */ `
  mutation AddMemberEvent($input: MemberEventInput!) {
    addMemberEvent(input: $input) {
      id
      memberId
      eventId
      isAutoAssigned
      lastModifiedBy
      siteLocationId
    }
  }
`;
export const removeSiteLocationAccessCategory = /* GraphQL */ `
  mutation RemoveSiteLocationAccessCategory($id: Int!) {
    removeSiteLocationAccessCategory(id: $id)
  }
`;
export const addAccessCategory = /* GraphQL */ `
  mutation AddAccessCategory($input: AddSiteLocationAccessCategoryInput!) {
    addAccessCategory(input: $input) {
      error
      errorType
      errorMsg
      rollbackStatus
    }
  }
`;
export const modifyAccessCategory = /* GraphQL */ `
  mutation ModifyAccessCategory($input: SiteLocationAccessCategoryInput!) {
    modifyAccessCategory(input: $input)
  }
`;
export const addSubscriptionToMember = /* GraphQL */ `
  mutation AddSubscriptionToMember(
    $userId: Int!
    $siteLocationMembershipId: Int!
    $isGranted: Boolean
  ) {
    addSubscriptionToMember(
      userId: $userId
      siteLocationMembershipId: $siteLocationMembershipId
      isGranted: $isGranted
    ) {
      id
      siteLocationMembershipId
      memberId
      status
      subscriptionStartDate
      subscriptionEndDate
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isSelected
      isNotMyGym
      isRejected
      isDeleted
      isGranted
    }
  }
`;
export const linkUserToMember = /* GraphQL */ `
  mutation LinkUserToMember($memberId: Int!, $userId: Int!) {
    linkUserToMember(memberId: $memberId, userId: $userId)
  }
`;
export const linkMember = /* GraphQL */ `
  mutation LinkMember($memberId: Int!) {
    linkMember(memberId: $memberId)
  }
`;
export const linkMemberToEvent = /* GraphQL */ `
  mutation LinkMemberToEvent($memberId: Int!, $eventId: Int!) {
    linkMemberToEvent(memberId: $memberId, eventId: $eventId)
  }
`;
export const unlinkMember = /* GraphQL */ `
  mutation UnlinkMember($memberId: Int!) {
    unlinkMember(memberId: $memberId)
  }
`;
export const addMember = /* GraphQL */ `
  mutation AddMember($input: MemberInput!) {
    addMember(input: $input) {
      memberId
      memberFirstName
      memberLastName
      isMobileAppDisabled
      isJustTapRevoked
      isStaffMember
      validFrom
      validTo
      signedUpDate
      phoneNumber
      status
      createdDate
      createdBy
      lastModifiedDate
      lastModifiedBy
      expiredDate
      isBlocked
      membership
      defaultGymDetectionMethod
      email
      profilePicturePath
      membershipStatus
      forcedAccessStartTime
      forcedAccessEndTime
      lastSyncDate
      subscriptionDeleted
      isNew
      isTrial
      arxReferenceId
      userId
    }
  }
`;
export const sendIssueCloseEmail = /* GraphQL */ `
  mutation SendIssueCloseEmail($input: IssueCloseEmailInput!) {
    sendIssueCloseEmail(input: $input) {
      error
      errorMessage
    }
  }
`;
export const addLocationAccessCategoryZone = /* GraphQL */ `
  mutation AddLocationAccessCategoryZone(
    $input: LocationAccessCategoryZoneInput
  ) {
    addLocationAccessCategoryZone(input: $input)
  }
`;
export const removeLocationAccessCategoryZone = /* GraphQL */ `
  mutation RemoveLocationAccessCategoryZone(
    $locationZoneId: Int!
    $locationAccessCategoryId: Int!
    $userId: Int!
  ) {
    removeLocationAccessCategoryZone(
      locationZoneId: $locationZoneId
      locationAccessCategoryId: $locationAccessCategoryId
      userId: $userId
    )
  }
`;
export const removeDaySchedules = /* GraphQL */ `
  mutation RemoveDaySchedules($id: Int) {
    removeDaySchedules(id: $id) {
      id
      name
      arxReferenceId
      startTime
      endTime
      status
      weekdayId
      weekdayName
      createdDate
      createdBy
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const addDaySchedule = /* GraphQL */ `
  mutation AddDaySchedule($input: DayScheduleInput!) {
    addDaySchedule(input: $input) {
      id
      name
      arxReferenceId
      startTime
      endTime
      status
      weekdayId
      weekdayName
      createdDate
      createdBy
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const modifyDaySchedule = /* GraphQL */ `
  mutation ModifyDaySchedule($input: DayScheduleInput!) {
    modifyDaySchedule(input: $input) {
      id
      name
      arxReferenceId
      startTime
      endTime
      status
      weekdayId
      weekdayName
      createdDate
      createdBy
      lastModifiedBy
      lastModifiedDate
    }
  }
`;
export const updateUserInformationRequest = /* GraphQL */ `
  mutation UpdateUserInformationRequest($userId: Int!, $updatedBy: Int!) {
    updateUserInformationRequest(userId: $userId, updatedBy: $updatedBy)
  }
`;
export const signUpMobileUser = /* GraphQL */ `
  mutation SignUpMobileUser($input: SignUpMobileUser!) {
    signUpMobileUser(input: $input) {
      id
      username
      firstName
      lastName
      phone
      email
      isActive
      language
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      appVersion
      profilePicturePath
      isProfilePicVerified
      isMobileAppUser
      isAdministrator
      isDeleted
      isSystemUser
      isMobileAppDisabled
      isJustTapRevoked
      defaultLocationDetectionMethod
      isMoreInfoRequested
      memberId
    }
  }
`;
export const addInquiryEvent = /* GraphQL */ `
  mutation AddInquiryEvent($input: InquiryEventInput!) {
    addInquiryEvent(input: $input) {
      id
      userId
      phoneNumber
      email
      dateOfBirth
      address
      title
      messageDescription
    }
  }
`;
export const addSiteSchedules = /* GraphQL */ `
  mutation AddSiteSchedules($input: SiteScheduleInput!) {
    addSiteSchedules(input: $input) {
      id
      siteId
      name
      type
      validFrom
      validTo
      utcValidFrom
      utcValidTo
      scheduleStatusId
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      status
    }
  }
`;
export const addSiteLocationSchedulesForAccessCategory = /* GraphQL */ `
  mutation AddSiteLocationSchedulesForAccessCategory(
    $input: SiteLocationScheduleForAccessCategoryInput!
  ) {
    addSiteLocationSchedulesForAccessCategory(input: $input)
  }
`;
export const addSiteScheduleDetails = /* GraphQL */ `
  mutation AddSiteScheduleDetails($input: SiteScheduleDetailsInput!) {
    addSiteScheduleDetails(input: $input)
  }
`;
export const addSiteLocationSchedulesForLocationAccessPoint = /* GraphQL */ `
  mutation AddSiteLocationSchedulesForLocationAccessPoint(
    $input: SiteLocationScheduleForLocationAccessPointInput!
  ) {
    addSiteLocationSchedulesForLocationAccessPoint(input: $input)
  }
`;
export const removeSiteSchedules = /* GraphQL */ `
  mutation RemoveSiteSchedules($id: Int!, $userId: Int!, $siteId: Int!) {
    removeSiteSchedules(id: $id, userId: $userId, siteId: $siteId)
  }
`;
export const removeSiteLocationSchedules = /* GraphQL */ `
  mutation RemoveSiteLocationSchedules($siteScheduleId: Int!, $userId: Int!) {
    removeSiteLocationSchedules(
      siteScheduleId: $siteScheduleId
      userId: $userId
    )
  }
`;
export const removeSiteSchedulesDetails = /* GraphQL */ `
  mutation RemoveSiteSchedulesDetails($siteScheduleId: Int!, $userId: Int!) {
    removeSiteSchedulesDetails(siteScheduleId: $siteScheduleId, userId: $userId)
  }
`;
export const updateScheduleStatusId = /* GraphQL */ `
  mutation UpdateScheduleStatusId(
    $siteScheduleId: Int!
    $scheduleStatusId: Int!
    $userId: Int!
    $siteId: Int!
  ) {
    updateScheduleStatusId(
      siteScheduleId: $siteScheduleId
      scheduleStatusId: $scheduleStatusId
      userId: $userId
      siteId: $siteId
    )
  }
`;
export const updateSiteSchedule = /* GraphQL */ `
  mutation UpdateSiteSchedule($input: SiteScheduleInput!) {
    updateSiteSchedule(input: $input)
  }
`;
export const removeSiteScheduleDetailRecord = /* GraphQL */ `
  mutation RemoveSiteScheduleDetailRecord(
    $id: Int!
    $userId: Int!
    $siteId: Int!
  ) {
    removeSiteScheduleDetailRecord(id: $id, userId: $userId, siteId: $siteId)
  }
`;
export const removeSiteLocationScheduleAccessCategoryRecord = /* GraphQL */ `
  mutation RemoveSiteLocationScheduleAccessCategoryRecord(
    $locationAccessCategoryId: Int!
    $zoneId: Int!
    $scheduleId: Int!
    $userId: Int!
    $siteId: Int!
  ) {
    removeSiteLocationScheduleAccessCategoryRecord(
      locationAccessCategoryId: $locationAccessCategoryId
      zoneId: $zoneId
      scheduleId: $scheduleId
      userId: $userId
      siteId: $siteId
    )
  }
`;
export const removeSiteLocationScheduleAccessPointRecord = /* GraphQL */ `
  mutation RemoveSiteLocationScheduleAccessPointRecord(
    $locationAccessPointId: Int!
    $zoneId: Int!
    $scheduleId: Int!
    $userId: Int!
    $siteId: Int!
  ) {
    removeSiteLocationScheduleAccessPointRecord(
      locationAccessPointId: $locationAccessPointId
      zoneId: $zoneId
      scheduleId: $scheduleId
      userId: $userId
      siteId: $siteId
    )
  }
`;
export const updateScheduleStatus = /* GraphQL */ `
  mutation UpdateScheduleStatus(
    $siteScheduleId: Int!
    $status: String!
    $userId: Int!
    $siteId: Int!
  ) {
    updateScheduleStatus(
      siteScheduleId: $siteScheduleId
      status: $status
      userId: $userId
      siteId: $siteId
    )
  }
`;
export const switchMobileApp = /* GraphQL */ `
  mutation SwitchMobileApp($id: Int!, $isDisable: Boolean!, $updatedBy: Int!) {
    switchMobileApp(id: $id, isDisable: $isDisable, updatedBy: $updatedBy)
  }
`;
export const addNewAccessCardHistory = /* GraphQL */ `
  mutation AddNewAccessCardHistory($input: AccessCardHistoryInput!) {
    addNewAccessCardHistory(input: $input) {
      existingCardsCount
    }
  }
`;
export const deactivateAccessCardForSiteLocation = /* GraphQL */ `
  mutation DeactivateAccessCardForSiteLocation(
    $memberId: Int!
    $siteLocationId: Int!
    $lastModifiedBy: Int!
    $deactivatedDateTime: String!
  ) {
    deactivateAccessCardForSiteLocation(
      memberId: $memberId
      siteLocationId: $siteLocationId
      lastModifiedBy: $lastModifiedBy
      deactivatedDateTime: $deactivatedDateTime
    )
  }
`;
export const updateAccessCardHistory = /* GraphQL */ `
  mutation UpdateAccessCardHistory(
    $cardId: String!
    $lastModifiedBy: Int!
    $deactivatedDateTime: String!
  ) {
    updateAccessCardHistory(
      cardId: $cardId
      lastModifiedBy: $lastModifiedBy
      deactivatedDateTime: $deactivatedDateTime
    )
  }
`;
export const removeUser = /* GraphQL */ `
  mutation RemoveUser($id: Int!, $updatedBy: Int!) {
    removeUser(id: $id, updatedBy: $updatedBy)
  }
`;
export const modifyMobileUser = /* GraphQL */ `
  mutation ModifyMobileUser($input: UserInput!) {
    modifyMobileUser(input: $input) {
      id
      username
      firstName
      lastName
      phone
      email
      isActive
      language
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      appVersion
      profilePicturePath
      isProfilePicVerified
      isMobileAppUser
      isAdministrator
      isDeleted
      isSystemUser
      isMobileAppDisabled
      isJustTapRevoked
      defaultLocationDetectionMethod
      isMoreInfoRequested
      memberId
    }
  }
`;
export const addWebUser = /* GraphQL */ `
  mutation AddWebUser($input: WebUserInput!) {
    addWebUser(input: $input) {
      error
      errorType
      errorMsg
      success
    }
  }
`;
export const updateWebUser = /* GraphQL */ `
  mutation UpdateWebUser($input: WebUserModifyInput!) {
    updateWebUser(input: $input) {
      error
      errorType
      errorMsg
      success
    }
  }
`;
export const deleteWebUser = /* GraphQL */ `
  mutation DeleteWebUser($email: String!, $lastModifiedUserId: Int!) {
    deleteWebUser(email: $email, lastModifiedUserId: $lastModifiedUserId) {
      error
      errorType
      errorMsg
      success
    }
  }
`;
export const giveForcedAccess = /* GraphQL */ `
  mutation GiveForcedAccess($input: GiveForcedAccessInput!) {
    giveForcedAccess(input: $input)
  }
`;
export const expireForcedAccess = /* GraphQL */ `
  mutation ExpireForcedAccess(
    $memberId: Int!
    $userId: Int!
    $siteLocationId: Int!
  ) {
    expireForcedAccess(
      memberId: $memberId
      userId: $userId
      siteLocationId: $siteLocationId
    )
  }
`;
export const pushGymVisitEntry = /* GraphQL */ `
  mutation PushGymVisitEntry($input: GymVisitActivityInput!) {
    pushGymVisitEntry(input: $input) {
      memberEventId
      memberId
      memberEmail
      eventId
      eventDescription
      eventType
      stageId
      stage
      createdTime
      isAutoAssigned
      memberEventStatusTypeId
      memberEventStatusType
      firstName
      lastName
      siteLocationId
      assignedUserId
      assignmentHistoryId
      locationName
      lastModifiedBy
      lastModifiedDate
      inquiryDescription
      memberLocationId
      informationReceivedEvent
    }
  }
`;
export const removeMobileUser = /* GraphQL */ `
  mutation RemoveMobileUser($userId: Int!, $memberId: Int) {
    removeMobileUser(userId: $userId, memberId: $memberId)
  }
`;
export const provisionMindbodyStaffAccount = /* GraphQL */ `
  mutation ProvisionMindbodyStaffAccount($input: ProvisionStaffAccountInput!) {
    provisionMindbodyStaffAccount(input: $input)
  }
`;
export const addStaffAccount = /* GraphQL */ `
  mutation AddStaffAccount($input: StaffAccountInput!) {
    addStaffAccount(input: $input)
  }
`;
export const removeStaffAccount = /* GraphQL */ `
  mutation RemoveStaffAccount($userId: Int!, $siteId: Int!) {
    removeStaffAccount(userId: $userId, siteId: $siteId)
  }
`;
export const mapMatchingRecords = /* GraphQL */ `
  mutation MapMatchingRecords($input: memberMappingInput!) {
    mapMatchingRecords(input: $input) {
      error
      errorType
      errorMsg
    }
  }
`;
export const deleteMemberLocationMembership = /* GraphQL */ `
  mutation DeleteMemberLocationMembership($id: Int!, $userId: Int!) {
    deleteMemberLocationMembership(id: $id, userId: $userId) {
      id
      siteLocationMembershipId
      memberId
      status
      subscriptionStartDate
      subscriptionEndDate
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isSelected
      isNotMyGym
      isRejected
      isDeleted
      isGranted
    }
  }
`;
export const addRequestInfoEvent = /* GraphQL */ `
  mutation AddRequestInfoEvent($input: RequestInfoMemberEventInput!) {
    addRequestInfoEvent(input: $input) {
      id
      userId
      phoneNumber
      email
      dateOfBirth
      address
      title
      messageDescription
    }
  }
`;
export const sendRequestMoreInformationEmail = /* GraphQL */ `
  mutation SendRequestMoreInformationEmail(
    $input: sendRequestMoreInformationEmailInput!
  ) {
    sendRequestMoreInformationEmail(input: $input) {
      error
      errorMessage
    }
  }
`;
export const mapMembersWithUser = /* GraphQL */ `
  mutation MapMembersWithUser($input: MapMembersWithUserInput) {
    mapMembersWithUser(input: $input) {
      id
      userId
    }
  }
`;
export const createMobileUser = /* GraphQL */ `
  mutation CreateMobileUser($input: CreateMobileUserInput) {
    createMobileUser(input: $input) {
      id
      username
      firstName
      lastName
      phone
      email
      isActive
      language
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      appVersion
      profilePicturePath
      isProfilePicVerified
      isMobileAppUser
      isAdministrator
      isDeleted
      isSystemUser
      isMobileAppDisabled
      isJustTapRevoked
      defaultLocationDetectionMethod
      isMoreInfoRequested
      memberId
    }
  }
`;
export const createOrGetGymMember = /* GraphQL */ `
  mutation CreateOrGetGymMember($input: CreateOrGetGymMemberInput) {
    createOrGetGymMember(input: $input) {
      memberId
      memberFirstName
      memberLastName
      isMobileAppDisabled
      isJustTapRevoked
      isStaffMember
      validFrom
      validTo
      signedUpDate
      phoneNumber
      status
      createdDate
      createdBy
      lastModifiedDate
      lastModifiedBy
      expiredDate
      isBlocked
      membership
      defaultGymDetectionMethod
      email
      profilePicturePath
      membershipStatus
      forcedAccessStartTime
      forcedAccessEndTime
      lastSyncDate
      subscriptionDeleted
      isNew
      isTrial
      arxReferenceId
      userId
    }
  }
`;
export const deleteMobileUser = /* GraphQL */ `
  mutation DeleteMobileUser($userId: Int!) {
    deleteMobileUser(userId: $userId)
  }
`;
export const reportIssue = /* GraphQL */ `
  mutation ReportIssue($input: ReportIssueInput!) {
    reportIssue(input: $input) {
      id
      userId
      phoneNumber
      email
      dateOfBirth
      address
      title
      messageDescription
    }
  }
`;
export const addFreeSubscriptionToMember = /* GraphQL */ `
  mutation AddFreeSubscriptionToMember(
    $userId: Int!
    $memberId: Int!
    $siteLocationMembershipId: Int!
    $isGranted: Boolean
    $isTrial: Boolean
  ) {
    addFreeSubscriptionToMember(
      userId: $userId
      memberId: $memberId
      siteLocationMembershipId: $siteLocationMembershipId
      isGranted: $isGranted
      isTrial: $isTrial
    ) {
      id
      siteLocationMembershipId
      memberId
      status
      subscriptionStartDate
      subscriptionEndDate
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isSelected
      isNotMyGym
      isRejected
      isDeleted
      isGranted
    }
  }
`;
export const updateSubscriptionMinusCrBalByMembershipId = /* GraphQL */ `
  mutation UpdateSubscriptionMinusCrBalByMembershipId(
    $membershipId: Int!
    $hasMinusCreditBalance: Boolean
  ) {
    updateSubscriptionMinusCrBalByMembershipId(
      membershipId: $membershipId
      hasMinusCreditBalance: $hasMinusCreditBalance
    ) {
      id
      siteLocationMembershipId
      memberId
      status
      subscriptionStartDate
      subscriptionEndDate
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isSelected
      isNotMyGym
      isRejected
      isDeleted
      isGranted
    }
  }
`;
export const addAccessPoint = /* GraphQL */ `
  mutation AddAccessPoint($input: AddAccessPointInput!) {
    addAccessPoint(input: $input)
  }
`;
export const modifyAccessPoint = /* GraphQL */ `
  mutation ModifyAccessPoint($input: ModifyAccessPointInput!) {
    modifyAccessPoint(input: $input)
  }
`;
export const modifyAccessRule = /* GraphQL */ `
  mutation ModifyAccessRule($input: AccessRuleInput!) {
    modifyAccessRule(input: $input)
  }
`;
export const revertAccessRule = /* GraphQL */ `
  mutation RevertAccessRule($ruleId: Int!, $userId: Int!) {
    revertAccessRule(ruleId: $ruleId, userId: $userId)
  }
`;
export const addMobileVisits = /* GraphQL */ `
  mutation AddMobileVisits($input: [MobileVisitInput]) {
    addMobileVisits(input: $input)
  }
`;
export const removeScheduleRequest = /* GraphQL */ `
  mutation RemoveScheduleRequest($id: Int!, $userId: Int!) {
    removeScheduleRequest(id: $id, userId: $userId)
  }
`;
export const resolveScheduleRequest = /* GraphQL */ `
  mutation ResolveScheduleRequest(
    $id: Int!
    $userId: Int!
    $dayScheduleId: Int!
  ) {
    resolveScheduleRequest(
      id: $id
      userId: $userId
      dayScheduleId: $dayScheduleId
    )
  }
`;
export const addGraceAccessEntry = /* GraphQL */ `
  mutation AddGraceAccessEntry(
    $subscriptionId: Int!
    $accessRuleId: Int!
    $userId: Int!
  ) {
    addGraceAccessEntry(
      subscriptionId: $subscriptionId
      accessRuleId: $accessRuleId
      userId: $userId
    )
  }
`;
export const updateMindbodyClientVisit = /* GraphQL */ `
  mutation UpdateMindbodyClientVisit($input: [UpdateClientVisitInput]) {
    updateMindbodyClientVisit(input: $input)
  }
`;
export const addMindbodyArrival = /* GraphQL */ `
  mutation AddMindbodyArrival($input: AddClientArrivalInput!) {
    addMindbodyArrival(input: $input) {
      id
      siteLocationMembershipId
      memberId
      status
      subscriptionStartDate
      subscriptionEndDate
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      isSelected
      isNotMyGym
      isRejected
      isDeleted
      isGranted
    }
  }
`;
export const sendMobileUserSignUpSuccessEmail = /* GraphQL */ `
  mutation SendMobileUserSignUpSuccessEmail($input: InvitationEmailInput!) {
    sendMobileUserSignUpSuccessEmail(input: $input) {
      error
      errorMessage
    }
  }
`;
export const createYellowboxBooking = /* GraphQL */ `
  mutation CreateYellowboxBooking($input: YellowboxCreateBookingInput) {
    createYellowboxBooking(input: $input) {
      lockerId
      location
      lockerNumber
      prefix
      prefixLong
      bookingStart
    }
  }
`;
export const endYellowboxBooking = /* GraphQL */ `
  mutation EndYellowboxBooking($lockerId: String!) {
    endYellowboxBooking(lockerId: $lockerId)
  }
`;
export const unlockYellowboxLocker = /* GraphQL */ `
  mutation UnlockYellowboxLocker($memberId: String!, $lockerId: String!) {
    unlockYellowboxLocker(memberId: $memberId, lockerId: $lockerId)
  }
`;
export const setYellowboxMemberPin = /* GraphQL */ `
  mutation SetYellowboxMemberPin($memberId: String!, $pin: String!) {
    setYellowboxMemberPin(memberId: $memberId, pin: $pin)
  }
`;
export const removeUserAccessCardOfPerson = /* GraphQL */ `
  mutation RemoveUserAccessCardOfPerson($input: ArxCarfOfPersonInput!) {
    removeUserAccessCardOfPerson(input: $input) {
      error
      errorType
      errorMsg
      statusCode
      statusMessage
    }
  }
`;
export const deactivateAccessCardForUser = /* GraphQL */ `
  mutation DeactivateAccessCardForUser(
    $userId: Int!
    $lastModifiedBy: Int!
    $deactivatedDateTime: String!
  ) {
    deactivateAccessCardForUser(
      userId: $userId
      lastModifiedBy: $lastModifiedBy
      deactivatedDateTime: $deactivatedDateTime
    )
  }
`;
export const addSiteLocationUserRecord = /* GraphQL */ `
  mutation AddSiteLocationUserRecord($input: SiteLocationUserRecordInput!) {
    addSiteLocationUserRecord(input: $input)
  }
`;
export const activateScheduleStatus = /* GraphQL */ `
  mutation ActivateScheduleStatus(
    $siteScheduleId: Int!
    $scheduleStatusId: Int!
    $userId: Int!
    $siteId: Int!
  ) {
    activateScheduleStatus(
      siteScheduleId: $siteScheduleId
      scheduleStatusId: $scheduleStatusId
      userId: $userId
      siteId: $siteId
    )
  }
`;
export const updateMemberFlag = /* GraphQL */ `
  mutation UpdateMemberFlag($flag: Int!, $memberId: Int!) {
    updateMemberFlag(flag: $flag, memberId: $memberId)
  }
`;
export const updateDefaultLocationDetectionMethod = /* GraphQL */ `
  mutation UpdateDefaultLocationDetectionMethod(
    $defaultLocationDetectionMethod: String!
    $id: Int!
  ) {
    updateDefaultLocationDetectionMethod(
      defaultLocationDetectionMethod: $defaultLocationDetectionMethod
      id: $id
    )
  }
`;
export const modifyProgramMapping = /* GraphQL */ `
  mutation ModifyProgramMapping($input: ModifyProgramInput!) {
    modifyProgramMapping(input: $input) {
      error
      errorType
      errorMsg
      rollbackStatus
    }
  }
`;
export const manualMemberImport = /* GraphQL */ `
  mutation ManualMemberImport($input: ManualMemberImportParams!) {
    manualMemberImport(input: $input) {
      error
      errorType
      errorMsg
      rollbackStatus
    }
  }
`;
export const updateMemberSyncStatus = /* GraphQL */ `
  mutation UpdateMemberSyncStatus($memberIds: String) {
    updateMemberSyncStatus(memberIds: $memberIds)
  }
`;
export const rejectMember = /* GraphQL */ `
  mutation RejectMember($memberId: Int!, $rejectedBy: Int!) {
    rejectMember(memberId: $memberId, rejectedBy: $rejectedBy)
  }
`;
export const markArrivals = /* GraphQL */ `
  mutation MarkArrivals($input: MarkArrivalsInput) {
    markArrivals(input: $input)
  }
`;
export const notifyClassPortal = /* GraphQL */ `
  mutation NotifyClassPortal($siteLocationId: Int!) {
    notifyClassPortal(siteLocationId: $siteLocationId) {
      id
      importedId
      name
      siteLocationId
      resourceId
      staffId
      startDateTime
      endDateTime
      maxCapacity
      totalBooked
      resourceMindbodyId
      resourceName
      resourceImageUrl
      staffMindbodyId
      staffName
      staffImageUrl
      imageUrl
    }
  }
`;
export const notifyEmployeeDashboard = /* GraphQL */ `
  mutation NotifyEmployeeDashboard($siteLocationId: Int!) {
    notifyEmployeeDashboard(siteLocationId: $siteLocationId) {
      id
      siteId
      siteLocationId
      kisiDoorId
      kisiDoorName
      doorOpenMemberEmail
      doorOpenedTime
      memberId
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      businessName
      memberName
      memberUniqueId
      memberClientId
      memberStatus
      memberAccountBalance
      memberNote
    }
  }
`;
